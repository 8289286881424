import {Component, OnInit} from "@angular/core";
import {FormGroup, FormControl} from "@angular/forms";
import {CrearePdfService} from "../../../_services/crearepdf.service";
import {ActivatedRoute, Router} from "@angular/router";
import {IApplication} from "../../../_models/application";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ApplicationService} from "../../../_services/application.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialog} from "../../../custom-dialog/custom-dialog.component";
import {AuthenticationService} from "../../../_services";
import {windowTime} from "rxjs/operators";
import {ConfirmationDialogError} from "../../../confirmation-dialog-error/confirmation-dialog-error.component";
import {ConfirmationDialogPDF} from "../../../custom-dialog-pdf/custom-dialog-pdf.component";
import {IInscriereStatus} from "../../../_models/inscriereStatus";
import {InscriereService} from "../../../_services/inscriere.service";

@Component({
  selector: "app-verif-aplic-user",
  templateUrl: "./verif-aplic-user.component.html",
  styleUrls: ["./verif-aplic-user.component.scss"]
})
export class VerifAplicUserComponent implements OnInit {
  stepId: number = 2;
  params: any;
  crearePdf = {} as IApplication;
  verifAppForm: FormGroup;
  message = {};
  fileDownloadUri: String;
  isClosed: boolean = false;
  isValidForClosure: boolean;

  constructor(
    private crearePdfService: CrearePdfService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private applicationService: ApplicationService,
    private dialog: MatDialog,
    private errorDialog: MatDialog,
    public authService: AuthenticationService,
    private inscriereService : InscriereService,
    private router: Router
  ) {
    this.verifAppForm = this.createFormGroup();
    this.params = this.route.params.subscribe(params => {
      this.crearePdf.Id = params["id"];
    });
  }

  ngOnInit() {
    this.applicationService.getApplication(this.crearePdf.Id).subscribe(d => {
      console.log("get app by id", this.crearePdf.Id, d);
      this.isClosed = d.closed;
      console.log("isClosed value ", this.isClosed);
    });

    this.getFisaInscriereStatus();
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(),
      applicationId: new FormControl(),
      // numeUniv: new FormControl(),
      numeFac: new FormControl(),
      // domeniu: new FormControl(),
      specializare: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      numeAnterioare: new FormControl(),
      cnp_passport: new FormControl(),
      cetateanStrain: new FormControl(),
      absolvent: new FormControl(),
      rrom: new FormControl()
    });
  }

  getFisaInscriereStatus() {
    this.inscriereService.getFisaInscriereStatus()
      .subscribe((status: IInscriereStatus) => {
        if (status.id) {
          this.isValidForClosure = true;
        } else {
          this.isValidForClosure = false;
        }
      });
  }

  isInscriereValidForClosure() {
    return this.isValidForClosure;
  }

  onSubmit() {
    if (this.isInscriereValidForClosure()) {
      console.log("submited");
      this.crearePdfService.crearePdf(this.crearePdf).subscribe(data => {
        console.log("S-a creat fisierul ", data);
        this.message = data;
        this.isClosed = true;

        console.log(decodeURI(this.message["fileDownloadUri"]));
        // this.fileDownloadUri = decodeURI(({ data } = fileDownloadUri));
      }, error => {
        alert(error);
        console.log("A eronat fisierul ", error);
      });
    } else {
      console.log("not submited");
      const dialogRef = this.errorDialog.open(ConfirmationDialogError, {
        data: {
            message: "Dosarul nu conține toate informațiile obligatorii. Vă rugam revizuiți dosarul și încercați să finalizați din nou.",
          buttonText: {
            ok: "OK"
          }
        }
      });
    }
  }

  downloadFile(filename: string = null): void {
    console.log("get pdf", localStorage.getItem("username"));
    this.http
      .get(
        environment.apiUrl +
        "/pdf/getPdfFile/" +
        localStorage.getItem("username") +
        ".pdf",
        {responseType: "blob" as "json"}
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        console.log(dataType);
        let binaryData = [];
        binaryData.push(response);

        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {type: dataType})
        );

        console.log(downloadLink.href);
        if (filename) downloadLink.setAttribute("download", filename);
        // document.body.appendChild(downloadLink);
        window.open(downloadLink.href, "_blank");
        // downloadLink.click();
      });
  }

  getPdfAfterUpdating() {
    this.crearePdfService.crearePdf(this.crearePdf, '/pdf/generate').subscribe(data => {
      console.log("S-a creat fisierul ", data);
      this.message = data;
      this.isClosed = false;
      this.downloadFile('Doar inscriere');
      console.log(decodeURI(this.message["fileDownloadUri"]));
      // this.fileDownloadUri = decodeURI(({ data } = fileDownloadUri));
    }, error => {
      alert(error);
      console.log("A eronat fisierul ", error);
    });
  }

  getPdf() {
    this.downloadFile("florin");
  }

  doSomething(id: any) {
    console.log("inscr user id: stepid: ", id, this.stepId);
    // if (this.stepId !== id) {
    //   console.log("events catch on child verif user: refresh data", id);
    //   console.log(
    //     "on page verif : check form !!! is prisitine or dirty,prisitne"
    //     // this.inscriereUserForm.dirty,
    //     // this.inscriereUserForm.pristine
    //   );
    //   alert("Salvati verif user?");
    // }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Sunteți sigur că doriți finalizarea dosarului ?",
        buttonText: {
          ok: "Da",
          cancel: "Nu"
        }
      }
    });
    // const snack = this.snackBar.open("Snack bar open before dialog");

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.onSubmit();
      } else {
        this.router.navigate(["/application/"]);
      }
    });
  }
}
