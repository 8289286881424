import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/do";
import { AuthenticationService } from "../_services";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public headers: HttpHeaders;
  constructor(public authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //append headers
    console.log("intercept request");
    this.headers = new HttpHeaders();
    this.headers.set("Content-Type", "application/json");
    this.headers.set("Access-Control-Allow-Origin", "*");
    this.headers.set(
      "Access-Control-Allow-Headers",
      "Origin, Authorization, Content-Type, Accept"
    );

    request = request.clone({
      headers: this.headers
    });
    // add authorization header with jwt token if available
    console.log("add authorization  ++++++++++++++++++++");
    console.log(
      "jwtinterceptor put token in header:",
      localStorage.getItem("token")
    );
    let token = localStorage.getItem("token");
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    console.log("on interceptor request is ", request);
    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpRequest) {
          console.log("ok");
        }
      },
      (err: any) => {
        console.log("onInterceptor handle error", err);
        //todo: check again
        // this.authService.logout();
      }
    );
  }
}
