import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../_services/authentication.service';
import {SetariService} from "../../../../_services/setari.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {platformdetails} from "../../../../../environments/platform-details";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  isPasswordsMatch: boolean;
  isChangeSubmited:boolean;
  warningMessage: string;
  token: string;
  resetForm: FormGroup;
  denumire_an: string;
  denumire_perioada: string;
  platform_name: string;

  constructor(
    public authenticationService: AuthenticationService,
    public setariService: SetariService,
    private formBuilder: FormBuilder,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.isPasswordsMatch = true;
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });

    this.isChangeSubmited = false;
    this.resetForm = this.formBuilder.group({
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(".{6,30}")
        ]
      ] ,
      passwordConfirmation: [
        "",
        [
          Validators.required,
          Validators.pattern(".{6,30}")
        ]
      ]
    });
    this.platform_name = platformdetails.title;
  }

  get f() {
    return this.resetForm.controls;
  }

  onChangePassword() {
    this.isChangeSubmited = true;

    const newPassword: string = this.resetForm.get('password').value;
    const newPasswordConfirmation: string = this.resetForm.get('passwordConfirmation').value;
    if (newPassword != newPasswordConfirmation || newPassword.length == 0) {
      this.isPasswordsMatch = false;
      this.warningMessage = "Noua parola nu este identica cu parola confirmata!"
      return;
    }

    console.log('[reset][password] send request');
    this.authenticationService.resetPassword(newPassword, newPasswordConfirmation, this.token)
      .subscribe(result => {
        console.log('[reset][password] success', result);
        if (result['success']) {
          this.router.navigate(['/login']);
        } else {
          let error = '';
          let messageArr = result['message']['password'];
          if (messageArr) {
            for (let i = 0; i < messageArr.length; i++) {
              error += messageArr[i] + ' ';
            }
          } else {
            error = result['message'];
          }
          this.warningMessage = error;
          this.isPasswordsMatch = false;
        }
      }, error => {
        console.log('[reset][password] success', error);
      });
  }
}
