import {Component, Input, OnInit} from "@angular/core";
import {IUser} from "../../../_models/user";
import {UserService} from "../../../_services/user.service";
import {platformdetails} from "../../../../environments/platform-details";
import {SetariService} from "../../../_services/setari.service";
import {Subject} from "rxjs";
import {IApplication} from "../../../_models/application";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"]
})
export class UserListComponent implements OnInit {
  pageTitle = "Lista utilizatori";
  private users: IUser[] = [];
  filteredUsers: IUser[] = [];
  userTemporary: IUser[] = [];
  private user: any;
  p: Number = 1;
  count: Number = 15;

  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  _listFilter = "";

  setGroupFilter$ = new Subject<any>();
  getGroupFilter = this.setGroupFilter$.asObservable();
  @Input() groupFilters: Object;
  @Input() searchByKeyword: string;

  constructor(
    public userService: UserService,
    public setariService: SetariService
  ) {}

  ngOnInit() {
    this.userService.getUsers().subscribe({
      next: users => {
        console.log("Users", users);
        this.users = users;
        this.userTemporary = this.jsonCopy(this.users);
      }
    });

    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });

    this.platform_name = platformdetails.title;
  }

  ngOnChanges(): void {
    console.log("onChange");
    if (this.groupFilters)
      this.filterUsers(this.groupFilters, this.users);
  }

  filterUsers(filters:any, users:any){
    let usersArray = users;
    this.filteredUsers = users;
    const filtersCriteria = Object.keys(filters);

    let filterResults = [];

    filtersCriteria.forEach(
      criteria =>  {
        filterResults = usersArray.filter(e =>
                String(e[criteria]).toLowerCase().includes(String(filters[criteria]).toLowerCase())
        )
        console.log("results is:", filterResults);
        usersArray = filterResults;
      });

    this.users = this.jsonCopy(usersArray);
  }

  groupFilters1($event) {
    if ($event) {
      this.users = this.jsonCopy(this.userTemporary);
      this.filterUsers($event, this.users);
    }
  }

  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredUsers = this.listFilter
      ? this.performFilter(this.listFilter)
      : this.users;
  }

  performFilter(filterBy: string): IUser[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.users.filter(
      (users: IUser) =>
        users.username.toLocaleLowerCase().indexOf(filterBy) !== -1
    );
  }

  jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  }
}
