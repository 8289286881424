import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { IApplication } from "../_models/application";
import { catchError, tap, map } from "rxjs/operators";
import { AppConfig } from "./../config/config";
import { IUser } from "../_models/user";
import { environment } from "../../environments/environment";
import { IApplicationHelper } from "../_models/applicationHelper";

@Injectable({
  providedIn: "root"
})
export class ApplicationService {
  private applicationUrl = "api/applications/applications.json";
  private applicationObservable: Observable<any[]>;

  constructor(private http: HttpClient) {}

  addApplication(application: IApplication): Observable<IApplication> {
    console.log(
      "adaugare aplicatie:",
      application,
      environment.apiUrl + "/application/add"
    );
    return this.http
      .post<IApplication>(environment.apiUrl + "/application/add", application)
      .pipe(
        tap(data => console.log("addApplications add" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  updateApplication(id: String | Number, evaluator: String, stare: String) {
    console.log(
      "update application in service",
      JSON.stringify({
        id: id,
        evaluator: evaluator
      }),
      environment.apiUrl + "/application/updateeval/"
    );
    return this.http
      .post(environment.apiUrl + "/application/updateeval/", {
        id: id,
        evaluator: evaluator,
        verificare: stare
      })
      .pipe(
        tap(data => console.log("user updated:", data)),
        catchError(this.handleError)
      );
  }
  updateApplicationClosed(id: Number, closed: boolean) {
    console.log(
      "update application in service",
      JSON.stringify({
        id: id,
        closed: closed
      }),
      environment.apiUrl + "/application/updateevalred/"
    );
    return this.http
      .post(environment.apiUrl + "/application/updateevalred/", {
        id: id,
        closed: closed
      })
      .pipe(
        tap(data => console.log("user updated:", data)),
        catchError(this.handleError)
      );
  }

  updateApplicationValidare(id: Number, user: String, type: String) {
    console.log(
      "update application in service for validare",
      JSON.stringify({
        id: id,
        user: user,
        type: type
      }),
      environment.apiUrl + "/application/updateevalvalidare/"
    );
    return this.http
      .post(environment.apiUrl + "/application/updateevalvalidare/", {
        id: id,
        user: user,
        type: type
      })
      .pipe(
        tap(data => console.log("validate updated:", data)),
        catchError(this.handleError)
      );
  }

  getApplications(): Observable<IApplication[]> {
    return this.http
      .get<IApplication[]>(environment.apiUrl + "/application/getAll/")
      .pipe(
        tap(data => console.log("All applications: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }
  getApplicationsHelper(): Observable<IApplicationHelper[]> {
    return this.http
      .get<IApplicationHelper[]>(environment.apiUrl + "/application/getAll/")
      .pipe(
        tap(data => console.log("All applications: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }
  getApplication(id: number): Observable<IApplication | undefined> {
    if (!isNaN(id)) {
      return this.http
        .get<IApplication>(
          environment.apiUrl + "/application/getApplicationById/" + id
        )
        .pipe(
          tap(data => {
            console.log("Application for id", data),
              catchError(this.handleError);
          })
        );
    }
  }
  getApplicationsByUser(
    username: string
  ): Observable<IApplication[] | undefined> {
    // console.log(
    //   "url:",
    //   environment.apiUrl + "/application/getAplicationsByUserName/" + username
    // );
    return this.http
      .get<IApplication[]>(
        environment.apiUrl + "/application/getAplicationsByUserName/" + username
      )
      .pipe(tap(data => console.log("fewef", data)));
  }
  private handleError(err) {
    let errorMessage = "";
    // console.log("applicaton service httpresponse is", err);
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    // console.error(errorMessage);
    return throwError(errorMessage);
  }
}
