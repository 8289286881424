import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../_services/authentication.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  username: string;

  constructor(public authService: AuthenticationService) {}

  ngOnInit(): void {

    this.username = localStorage.getItem("username");
  }

  logout() {
    console.log("logout called");
    this.authService.logout();
  }
}
