import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

export interface AdaugareDialogData {
  denumire: any;
  date_inceput: any;
  date_sfarsit: any;
}
@Component({
  selector: "adaugare-dialog",
  templateUrl: "adaugare-dialog.html"
})
export class AdaugareDialog {
  message: string;
  confirmButtonText: any;
  cancelButtonText: any;
  denumire: any;
  date_inceput: any;
  date_sfarsit: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdaugareDialogData,
    private dialogRef: MatDialogRef<AdaugareDialog>
  ) {
    if (data) {
      console.log("adaugare-dialog data", data);
    }
  }

  onConfirmClick(): void {
    console.log("Confirmed in adaugare-dialog", this.data);
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
