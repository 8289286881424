import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../_services/authentication.service";
import { Router } from "@angular/router";
import { timer } from "rxjs/observable/timer";
import { IUser } from "../../../_models/user";
import { InfPersUserService } from "../../../_services/inf-pers-user.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SetariService } from "../../../_services/setari.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  public email: string = "";
  // public name: string = "";
  // public password: string = "";
  public warningMessage: string;
  public message: string;
  public user: IUser;
  registerForm: FormGroup;
  submitted = false;
  public denumire_an: string;
  public denumire_perioada: string;
  platform_name: string;

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    private formBuilder: FormBuilder,
    public setariService: SetariService
  ) {}

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.registerForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ]
    });
    this.platform_name = platformdetails.title;
  }

  get f() {
    return this.registerForm.controls;
  }

  onRegister() {
    this.submitted = true;
    this.user = {} as IUser;
    console.log("user:", this.registerForm.value.email);
    this.user.username = this.registerForm.value.email;
    if (this.registerForm.invalid) {
      console.log("return");
      return;
    }
    // const source = timer(5000);
    this.authService.register(this.user).subscribe(
      res => {
        console.log("register ts", res);
        if (!this.message) {
          this.message =
            "Inregistrare cu succes! A fost trimis un email cu instructiuni la aceasta adresa. Accesati " +
            "contul de mail pentru a va confirma inregistrarea in aplicatie.";
          // const subscribe = source.subscribe(val => {
          //   this.router.navigate(["login"]);
          // });
        }
      },
      error => {
        this.warningMessage = error + "!";
        console.error(error);
      }
    );
  }
}
