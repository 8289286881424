import { Component, OnInit } from "@angular/core";
import { IApplication } from "../../../_models/application";

@Component({
  selector: "app-application-detail-admin",
  templateUrl: "./application-detail-admin.component.html",
  styleUrls: ["./application-detail-admin.component.scss"]
})
export class ApplicationDetailAdminComponent implements OnInit {
  pageTitle = "Your Application List";
  private _applications: IApplication[] = [];
  private _application: any;

  private username: String;
  private role: String;
  constructor() {}

  ngOnInit() {}
}
