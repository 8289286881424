import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import {
  RegisterComponent,
  LoginComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent
} from "./_components/auth/index";
import { AuthGuard } from "./_guards/auth.guard";
import { ApplicationListComponent } from "./_components/home/application-list/application-list.component";
import { ApplicationDetailComponent } from "./_components/home/application-detail/application-detail.component";
import { RoleGuardService } from "./_guards/role-guard.service";
import { PagenotfoundComponent } from "./_components/pagenotfound/pagenotfound.component";
import { AdminDashboardComponent } from "./_components/admin-dashboard/admin-dashboard.component";
import { UserListComponent } from "./_components/admin-dashboard/user-list/user-list.component";
import { UserDetailComponent } from "./_components/admin-dashboard/user-detail/user-detail.component";
import { ApplicationDetailAdminComponent } from "./_components/admin-dashboard/application-detail-admin/application-detail-admin.component";
import { ApplicationListAdminComponent } from "./_components/admin-dashboard/application-list-admin/application-list-admin.component";
import { RapoarteComponent } from "./_components/admin-dashboard/rapoarte/rapoarte.component";
import { SetariComponent } from "./_components/admin-dashboard/setari/setari.component";
import { RegisterConfirmComponent } from "./_components/auth/register-confirm/register-confirm.component";
import { EvalApplicationListComponent } from "./_components/eval/eval-application-list/evalapplication-list.component";
import { EvalApplicationDetailComponent } from "./_components/eval/eval-application-detail/evalapplication-detail.component";
import { SecretarApplicationListComponent } from "./_components/secretar/eval-application-list/evalapplication-list.component";
import { SecretarInscriereEvalComponent } from "./_components/secretar/inscriere-eval/inscriere-eval.component";
import { AddSetariComponent } from "./_components/admin-dashboard/setari/addsetari/addsetari.component";
import {SecretarComisieApplicationListComponent} from "./_components/secretar_comisie/eval-application-list/evalapplication-list.component";
import {SecretarComisieInscriereEvalComponent} from "./_components/secretar_comisie/inscriere-eval/inscriere-eval.component";

const appRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "registerconfirm/:token", component: RegisterConfirmComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  {
    path: "admin",
    component: AdminDashboardComponent,
    children: [
      // { path: "", component: AdminDashboardComponent },
      { path: "users", component: UserListComponent },
      {
        path: "user/:id",
        component: UserDetailComponent
      },
      { path: "application", component: ApplicationListAdminComponent },
      { path: "application/:id", component: ApplicationDetailAdminComponent },
      { path: "reports", component: RapoarteComponent },
      {
        path: "settings",
        component: SetariComponent
      }
      // { path: "settings/addsettings", component: AddSetariComponent }
    ],
    canActivate: [RoleGuardService]
  },
  {
    path: "eval",
    children: [
      { path: "", component: EvalApplicationListComponent },
      { path: "applicationeval/:id", component: EvalApplicationDetailComponent }
    ],
    canActivate: [RoleGuardService]
  },
  {
    path: "secretar",
    children: [
      { path: "", component: SecretarApplicationListComponent },
      { path: "applicationeval/:id", component: SecretarInscriereEvalComponent }
    ],
    canActivate: [RoleGuardService]
  },
  {
    path: "secretar_comisie",
    children: [
      { path: "", component: SecretarComisieApplicationListComponent },
      { path: "applicationeval/:id", component: SecretarComisieInscriereEvalComponent }
    ],
    canActivate: [RoleGuardService]
  },

  {
    path: "application",
    children: [
      { path: "", component: ApplicationListComponent },
      { path: "add", component: ApplicationDetailComponent },
      { path: "application/:id", component: ApplicationDetailComponent }
    ],
    canActivate: [RoleGuardService]
    // data: { expectedRole: ["ROLE_USER"] }
  },
  { path: "", component: LoginComponent },
  { path: "**", redirectTo: "login" }
];

export const routing = RouterModule.forRoot(appRoutes); //, { enableTracing: true }
