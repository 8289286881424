import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicationService } from "../../../_services/application.service";
import { Subject } from "rxjs";
import { IKey } from "../../../_models/key";
import { SetariService } from "../../../_services/setari.service";

@Component({
  selector: "app-home-eval",
  templateUrl: "./evalapplication-detail.component.html",
  styleUrls: ["./evalapplication-detail.component.scss"]
})
export class EvalApplicationDetailComponent implements OnInit, OnDestroy {
  public denumire_an: string;
  public denumire_perioada: string;
  data: IKey;
  private eventsSubject: Subject<IKey> = new Subject<IKey>();
  params: any;
  applicationId: number;
  username: string;
  emitEventToChild(id: any, type: string) {
    console.log("emit to child -----------", id, type);
    this.eventsSubject.next({ name: id, value: type });
  }

  ngOnDestroy(): void {
    console.log("App list destroy");
  }
  public title: string = "Inscriere electronica";

  constructor(
    private applicationService: ApplicationService,
    public route: ActivatedRoute,
    public setariService: SetariService
  ) {
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
  }

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.username = localStorage.getItem("username");
  }

  addApplication(application) {
    this.applicationService
      .addApplication(application)
      .subscribe(application => console.log("rggr5g"));
  }
  openOptions() {
    window.document.getElementById("dashboard-button").click();
  }

  public onStepChange(event: any): void {
    console.log("step changed emit to ------", event.previouslySelectedIndex);
    this.emitEventToChild(event.previouslySelectedIndex, "save");
    // this.emitEventToChild(event.selectedIndex, "refresh");
    // console.log("step changed emit to ------", event.previouslySelectedIndex);
  }
}
