import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { IInfPers } from "../_models/infpers";
import { Observable, throwError } from "rxjs";
import { AppConfig } from "./../config/config";

import { catchError, tap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class InfPersUserService {
  constructor(private http: HttpClient) {}

  addInfPers(infpers: IInfPers, username: string): Observable<IInfPers> {
    infpers.username = username;
    console.log("InfPersService", infpers, environment.apiUrl + "/infpers/add");
    return this.http
      .post<IInfPers>(environment.apiUrl + "/infpers/add", infpers)
      .pipe(
        tap(data => console.log("addInfPers add" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getInfPersUsers(): Observable<IInfPers[]> {
    return this.http.get<IInfPers[]>(AppConfig.baseURL).pipe(
      tap(data => console.log("All: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getInfPersUser(username: String): Observable<IInfPers[]> {
    return this.http
      .get<IInfPers[]>(
        environment.apiUrl + "/infpers/getInfPersByUserName/" + username
      )
      .pipe(
        tap(
          data => console.log("infperservice data", data)
          // console.log(
          //   "infpers for id: " +
          //     environment.apiUrl +
          //     "/infpers/getInfPersByUserId/" +
          //     id
          // )
        ),
        // console.log("infpers for id: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  updateInscriereUser(infpers: IInfPers): Observable<IInfPers> {
    // inscriereUser.username = username;
    console.log(
      "InfPersService",
      infpers,
      environment.apiUrl + "/inscuser/update"
    );
    return this.http
      .post<IInfPers>(environment.apiUrl + "/inscuser/update", infpers)
      .pipe(
        tap(data => console.log("addInfPers updated" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getInfPersByApplicationId(id: number): Observable<IInfPers[]> {
    return this.http
      .get<IInfPers[]>(
        environment.apiUrl + "/infpers/getInfPersByApplicationId/" + id
      )
      .pipe(
        tap(data => console.log("infpers for id: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
