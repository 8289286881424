// all angular imports
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  BrowserAnimationsModule,
  NoopAnimationsModule
} from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LayoutModule } from "@angular/cdk/layout";
import "hammerjs";

// all app modules
import { MaterialModule } from "./_modules/index";
import { routing } from "./app-routing.module";
// import { HashLocationStrategy, LocationStrategy } from "@angular/common";

// all guards
import { AuthGuard } from "./_guards/auth.guard";

// all intercepters
import { JwtInterceptor, ErrorInterceptor } from "./_helpers";

// all services
import { AuthenticationService, FileService } from "./_services/index";

// all components
import { AppComponent } from "./_components/app.component";
import {
  LoginComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent
} from "./_components/auth/index";
import { ApplicationDetailComponent } from "./_components/home/application-detail/application-detail.component";
import { InfpersUserComponent } from "./_components/home/infpers-user/infpers-user.component";
import { InscriereUserComponent } from "./_components/home/inscriere-user/inscriere-user.component";
import { DocumenteUserComponent } from "./_components/home/documente-user/documente-user.component";
import { ScrisIntentieUserComponent } from "./_components/home/scris-intentie-user/scris-intentie-user.component";
import { VerifAplicUserComponent } from "./_components/home/verif-aplic-user/verif-aplic-user.component";
import { ApplicationListComponent } from "./_components/home/application-list/application-list.component";
import { JwtHelperService } from "@auth0/angular-jwt";
import { PagenotfoundComponent } from "./_components/pagenotfound/pagenotfound.component";
import { RoleGuardService } from "./_guards/role-guard.service";
import { AdminDashboardComponent } from "./_components/admin-dashboard/admin-dashboard.component";
import { UserListComponent } from "./_components/admin-dashboard/user-list/user-list.component";
import { UserDetailComponent } from "./_components/admin-dashboard/user-detail/user-detail.component";
import { ApplicationDetailAdminComponent } from "./_components/admin-dashboard/application-detail-admin/application-detail-admin.component";
import { ApplicationListAdminComponent } from "./_components/admin-dashboard/application-list-admin/application-list-admin.component";
import { NgxPaginationModule } from "ngx-pagination";
import { RapoarteComponent } from "./_components/admin-dashboard/rapoarte/rapoarte.component";
import { SetariComponent } from "./_components/admin-dashboard/setari/setari.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { RegisterConfirmComponent } from "./_components/auth/register-confirm/register-confirm.component";
import { SelectFacultatiService } from "./_services/selectFacultati";
import { FileSelectDirective } from "ng2-file-upload";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { ConfirmationDialog } from "./custom-dialog/custom-dialog.component";
import { EvalApplicationListComponent } from "./_components/eval/eval-application-list/evalapplication-list.component";
import { EvalApplicationDetailComponent } from "./_components/eval/eval-application-detail/evalapplication-detail.component";
import { SecretarApplicationListComponent } from "./_components/secretar/eval-application-list/evalapplication-list.component";
import { SecretarInscriereEvalComponent } from "./_components/secretar/inscriere-eval/inscriere-eval.component";
import { SecretarComisieApplicationListComponent } from "./_components/secretar_comisie/eval-application-list/evalapplication-list.component";
import { SecretarComisieInscriereEvalComponent } from "./_components/secretar_comisie/inscriere-eval/inscriere-eval.component";
import { InscriereEvalComponent } from "./_components/eval/inscriere-eval/inscriere-eval.component";
import { DocumenteEvalComponent } from "./_components/eval/documente-eval/documente-eval.component";
import { VerifAplicEvalComponent } from "./_components/eval/verificare-eval/verif-aplic-eval.component";
import { ConfirmationDialogPDF } from "./custom-dialog-pdf/custom-dialog-pdf.component";
import { FilterPipe } from "./filter.pipe";
import { SearchComponent } from "./search/search.component";
import { Globals } from "./_models/globals";
import { AdaugareDialog } from "./adaugare-dialog/adaugare-dialog.component";
import { CommonModelService } from "./adaugare-dialog/common-dialog.service";
import { ConfirmationDialogError } from './confirmation-dialog-error/confirmation-dialog-error.component';
import {SearchUserComponent} from "./search.users/search-user.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ApplicationDetailComponent,
    InfpersUserComponent,
    InscriereUserComponent,
    DocumenteUserComponent,
    ScrisIntentieUserComponent,
    VerifAplicUserComponent,
    ApplicationListComponent,
    PagenotfoundComponent,
    AdminDashboardComponent,
    UserListComponent,
    UserDetailComponent,
    ApplicationDetailAdminComponent,
    ApplicationListAdminComponent,
    RapoarteComponent,
    SetariComponent,
    RegisterConfirmComponent,
    FileSelectDirective,
    ConfirmationDialog,
    AdaugareDialog,
    ConfirmationDialogPDF,
    EvalApplicationListComponent,
    EvalApplicationDetailComponent,
    SecretarApplicationListComponent,
    SecretarInscriereEvalComponent,
    SecretarComisieApplicationListComponent,
    SecretarComisieInscriereEvalComponent,
    InscriereEvalComponent,
    DocumenteEvalComponent,
    VerifAplicEvalComponent,
    FilterPipe,
    SearchComponent,
    ConfirmationDialogError,
    SearchUserComponent
  ],
  entryComponents: [ConfirmationDialog, ConfirmationDialogPDF, ConfirmationDialogError, AdaugareDialog],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    LayoutModule,
    MaterialModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: JwtHelperService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    RoleGuardService,
    AuthenticationService,
    FileService,
    SelectFacultatiService,
    Globals,
    CommonModelService
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
