import { Component, OnInit } from "@angular/core";
import { SetariService } from "../../../_services/setari.service";
import { ISetari } from "../../../_models/setari";
import { MatDialog } from "@angular/material";
import { AdaugareDialog } from "../../../adaugare-dialog/adaugare-dialog.component";
import { CommonModelService } from "../../../adaugare-dialog/common-dialog.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "app-setari",
  templateUrl: "./setari.component.html",
  styleUrls: ["./setari.component.scss"]
})
export class SetariComponent implements OnInit {
  private setari: ISetari[] = [];
  private set: any;
  p: Number = 1;
  count: Number = 5;
  denumire: any;
  date_inceput: any;
  date_sfarsit: any;

  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  constructor(
    public setariService: SetariService,
    private dialog: MatDialog,
    private commModel: CommonModelService
  ) {}

  ngOnInit() {
    this.platform_name = platformdetails.title;
    this.fetchData();
  }

  fetchData() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.setariService.getSetari().subscribe({
      next: setari => {
        console.log("setari:", setari);
        this.setari = setari.reverse();
      }
    });
  }

  openDialog() {
    this.commModel.openDialog().subscribe(res => {
      console.log("setari componenter", res);
      if (res) {
        this.setariService.addSetari(res).subscribe({
          next: res => {
            console.log("setari:", res);
            this.fetchData();
          }
        });
      }
    });
  }
}
