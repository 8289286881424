import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse, HttpParams
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, repeat } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { IUser } from "../_models/user";
import jwt_decode from "jwt-decode";
import { Router } from "@angular/router";
const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthenticationService {
  public token: string;
  public role: string;
  public username: string;
  public tokenPayload: any;
  public headers: HttpHeaders;
  public readonly apiUrl = environment.apiUrl;
  public readonly baseUrl = environment.baseUrl;

  constructor(public http: HttpClient, public router: Router) {
    // set token if saved in local storage
    // var token = this.token; //JSON.parse(localStorage.getItem("user"));
    // this.token = currentUser && currentUser.token;
  }

  getUserName(): Observable<string> {
    const user = localStorage.getItem("username");
    if (user) {
      // var json = JSON.parse(user);
      return (user as unknown) as Observable<string>;
    }
  }

  isLoggedIn() {
    // this.tokenPayload = jwt_decode(this.token);
    // console.log("in is logged ");
    if (localStorage.getItem("token")) {
      // console.log("is logged in ?????", localStorage.getItem("token"));
      return true; //!jwtHelper.isTokenExpired(this.tokenPayload["exp"]);
    }
    return false;
  }

  getRole() : Observable<string> {
    const role = localStorage.getItem("role");
    if(role) {
      return (role as unknown) as Observable<string>;
    }
  }

  login(email: string, password: string): Observable<any> {
    return this.http
      .post(this.apiUrl + "/user/login", {
        userName: email,
        password: password
      })
      .pipe(
        map((response: Response) => {
          // login successful if there's a jwt token in the response

          console.log("response login gwerkgowerkgo", response);
          this.token = response["token"];
          console.log("token in auth service is ", jwt_decode(this.token));
          if (this.token) {
            localStorage.setItem("token", this.token);
            this.tokenPayload = jwt_decode(this.token);
            this.username = this.tokenPayload["sub"];
            this.role = this.tokenPayload["roles"];

            localStorage.setItem("username", this.username);
            localStorage.setItem("role", this.role);
            let expiresIn = this.tokenPayload["exp"];
          }
          // if (this.token) {
          //   // store expiresIn and jwt token in local storage to keep user logged in between page refreshes
          //   localStorage.setItem(
          //     "user",
          //     JSON.stringify({ expires_in: expiresIn, token: this.token })
          //   );
          //   localStorage.setItem("role", this.role);
          //   localStorage.setItem("username", this.username);
          // }
          return response;
        })
      );
  }

  register(user: IUser): Observable<any> {
    console.log("auth service register:", this.apiUrl + "/user/register");
    console.log("auth service register:", user);

    return this.http
      .post(this.apiUrl + "/user/register", {
        userName: user.username,
        password: user.password
      })
      .pipe(
        map((response: Response) => {
          // register successful if there's a jwt token in the response
          this.token = response["token"];
          let expiresIn = response["expires_in"];
          if (this.token) {
            // store expiresIn and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              "user",
              JSON.stringify({ expires_in: expiresIn, token: this.token })
            );
          }
          return response;
        })
      );
  }

  registerConfirm(token: string): Observable<any> {
    console.log(
      "in registerConfirm service",
      this.apiUrl + "/user/registrationConfirm/" + token
    );
    return this.http
      .get(this.apiUrl + "/user/registrationConfirm/" + token)
      .pipe(
        map((response: Response) => {
          console.log("registrationconfirm", response);
          return response;
        })
      );
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = null;
    localStorage.clear();
    this.router.navigate(["/"]);
  }

  sendPasswordResetEmail(email: string): Observable<any> {
    return this.http
      .post(this.apiUrl + "/user/resetPassword", email)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  resetPassword(newPassword: string, confirmedPassword: string, token: string): Observable<any> {
    let data = {
      password: newPassword,
      confirm: confirmedPassword
    };

    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key, data[key]);
    });

    return this.http.post(this.apiUrl + "/user/confirmPassword/" + token, httpParams)
      .pipe(
        map((response: Response) => {
          return response;
        })
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    console.log("applicaton service httpresponse is", err);
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
