import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { IInscriereUser } from "../_models/inscriereUser";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class InscriereUserService {
  constructor(private http: HttpClient) {}

  getInscriereUserByAppId(id: number): Observable<IInscriereUser[]> {
    return this.http
      .get<IInscriereUser[]>(
        environment.apiUrl + "/inscuser/getInscriereUserByAppId/" + id
      )
      .pipe(
        tap(data => console.log("getInscriereUserById data", data)),
        catchError(this.handleError)
      );
  }

  getInscriereUserEvalByAppId(id: number): Observable<IInscriereUser[]> {
    return this.http
      .get<IInscriereUser[]>(
        environment.apiUrl + "/inscuser/getInscriereUserEvalByAppId/" + id
      )
      .pipe(
        tap(data => console.log("getInscriereUserById eval data", data)),
        catchError(this.handleError)
      );
  }

  updateInscriereUser(
    inscriereUser: IInscriereUser
  ): Observable<IInscriereUser> {
    // inscriereUser.username = username;
    console.log(
      "InfPersService",
      inscriereUser,
      environment.apiUrl + "/inscuser/update"
    );
    return this.http
      .post<IInscriereUser>(environment.apiUrl + "/inscuser/update", inscriereUser);
      // .pipe(
      //   tap(data => console.log("addInfPers updated" + JSON.stringify(data))),
      //   catchError(this.handleError)
      // );
  }

  updateInscriereUserEval(
    appid: number,
    validat: boolean,
    observatii: String
  ): Observable<IInscriereUser> {
    // inscriereUser.username = username;
    console.log("InfPersService", environment.apiUrl + "/inscuser/updateeval");
    return this.http
      .post<IInscriereUser>(environment.apiUrl + "/inscuser/updateeval", {
        appid,
        validat,
        observatii
      })
      .pipe(
        tap(data =>
          console.log("inscr user eval updated" + JSON.stringify(data))
        ),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  downloadFisaInscriere() {
    return this.http.get(environment.apiUrl + "/pdf/getFisaInscriere",
      {responseType: "blob" as "json"});
  }
}
