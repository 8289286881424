import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../_services/authentication.service";
import { Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import { SetariService } from "../../../_services/setari.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  public email: string = "";
  public password: string = "";
  public warningMessage: string;
  public denumire_an: string;
  public denumire_perioada: string;
  public sfarsit_modificari: string;
  public platform_name: string;

  constructor(
    public authService: AuthenticationService,
    public router: Router,
    public setariService: SetariService
  ) {}

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
      this.sfarsit_modificari = res.date_sfarsit_modificari;
    });
    this.platform_name = platformdetails.title;
  }

  onLogIn() {
    console.log("login here", this.email, this.password);
    this.authService.login(this.email, this.password).subscribe(
      res => {
        console.log("login res", jwt_decode(res["token"])["roles"]);
        if (jwt_decode(res["token"])["roles"] == "ROLE_ADMIN") {
          console.log("navigate to admin dashboard");
          this.router.navigate(["admin/reports"]);
        }
        if (jwt_decode(res["token"])["roles"] === "ROLE_EVAL") {
          this.router.navigate(["eval"]);
          console.log("navigate to evaluator dashboard");
        }
        if (jwt_decode(res["token"])["roles"] === "ROLE_SECRETAR") {
          console.log("navigate to evaluator dashboard");
          this.router.navigate(["secretar"]);
        }
        if (jwt_decode(res["token"])["roles"] === "ROLE_SECRETAR_COMISIE") {
          console.log("navigate to evaluator dashboard");
          this.router.navigate(["secretar_comisie"]);
        }
        if (jwt_decode(res["token"])["roles"] == "ROLE_USER") {
          console.log("navigate to user dashboard");
          this.router.navigate(["application"]);
        }
        //   }
        //   //check for errors
        //   this.warningMessage = "";
        //   if (Array.isArray(res)) {
        //     this.warningMessage += res[0];
        //   }
        //   console.log("this.warningMessage", this.warningMessage);
        //   this.router.navigate(["applications"]);
        //   // if not errors - navigate to home
        //   if (!this.warningMessage) this.router.navigate(["/"]);
      },
      error => {
        this.warningMessage = error + "!";
        console.error("login error", error);
      }
    );
  }
}
