import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef
} from "@angular/core";
import { ApplicationService } from "../../../_services/application.service";
import { IApplication } from "../../../_models/application";
import { MatDialog } from "@angular/material";
import { ConfirmationDialog } from "../../../custom-dialog/custom-dialog.component";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { IApplicationHelper } from "../../../_models/applicationHelper";
import { Subject } from "rxjs";
import { debug } from "util";
import { SetariService } from "../../../_services/setari.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "app-application-list-eval",
  templateUrl: "./evalapplication-list.component.html",
  styleUrls: ["./evalapplication-list.component.scss"]
})
export class SecretarApplicationListComponent implements OnInit, OnChanges {
  private username: String;
  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  private dosareInAsteptare = 0;
  private dosareInLucru = 0;
  private dosareIncomplete = 0;
  private dosareCompletate = 0;
  private dosareValidate = 0;
  private totalDosare = 0;

  private role: String;
  private applications: IApplicationHelper[] = [];
  private applicationsTemp: IApplicationHelper[] = [];
  filteredApplications: IApplicationHelper[] = [];
  _listFilter = "";
  setGroupFilter$ = new Subject<any>();
  getGroupFilter = this.setGroupFilter$.asObservable();
  @Input() groupFilters: Object;
  @Input() searchByKeyword: string;

  private application: any;
  p: Number = 1;
  count: Number = 10;

  constructor(
    private applicationService: ApplicationService,
    private dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private ref: ChangeDetectorRef,
    public setariService: SetariService
  ) {}

  ngOnChanges(): void {
    console.log("onChange");
    if (this.groupFilters)
      this.filterApplications(this.groupFilters, this.applications);
  }

  groupFilters1($event) {
    if ($event) {
      this.applications = this.jsonCopy(this.applicationsTemp);
      this.filterApplications($event, this.applications);
    }
  }

  filterApplications(filters: any, applications: any) {
    let myArray = applications;
    this.filteredApplications = applications;
    const keys = Object.keys(filters);
    let b = [];
    keys.forEach(key => {
      b = myArray.filter(e =>
        String(e[key])
          .toLowerCase()
          .startsWith(String(filters[key]).toLowerCase())
      );
      console.log("b is:", b);
      myArray = b;
    });
    // applications = this.jsonCopy(myArray);
    // this.filteredApplications = this.jsonCopy(myArray);
    this.applications = this.jsonCopy(myArray);
    this.updateMiniraport(this.applications);
  }

  updateMiniraport(applications: IApplicationHelper[]) {
    this.dosareInAsteptare = this.dosareInLucru = this.dosareIncomplete = this.dosareCompletate = this.dosareValidate = 0;

    applications.forEach((app) => {
      switch (app.verificare) {
        case 'In asteptare':
          this.dosareInAsteptare += 1;
          break;
        case 'In lucru':
          this.dosareInLucru += 1;
          break;
        case 'Incomplet':
          this.dosareIncomplete += 1;
          break;
        case 'Completat':
          this.dosareCompletate += 1;
          break;
        case 'Validat':
          this.dosareValidate += 1;
          break;
        default:
          console.log('Nu este cunoscut status: ' + app.verificare);
      }
    });
    this.totalDosare = applications.length;
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");
    this.role = localStorage.getItem("role");
    this.platform_name = platformdetails.title;
    this.fetchData();
  }

  fetchData() {
    console.log("fetch data");
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.applicationService.getApplicationsHelper().subscribe({
      next: applications => {
        console.log("app for role eval", applications);
        this.applications = applications;
        this.updateMiniraport(this.applications);
        this.applicationsTemp = this.jsonCopy(this.applications);
      }
    });
  }
  openDialog(application) {
    console.log(application.evaluator);
    if (application.evaluator === this.username) {
      this.router.navigate(["eval/applicationeval/", application.id]);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message:
            "Sunteti sigur ca doriti preluarea si verificarea dosarului ?",
          buttonText: {
            ok: "Da",
            cancel: "Nu"
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          // console.log("update eval---------", application);
          application.evaluator = localStorage.getItem("username");
          this.applicationService
            .updateApplication(
              application.id,
              application.evaluator,
              "In lucru"
            )
            .subscribe({
              next: app => {
                this.router.navigate(["eval/applicationeval/", application.id]);
              }
            });
          console.log("confirmed");
        }
      });
    }
  }

  openDialogRedeschide(application) {
    console.log(application.evaluator);

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Sunteti sigur ca doriti redeschiderea dosarului ?",
        buttonText: {
          ok: "Da",
          cancel: "Nu"
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        //update eval
        console.log("redeschidere aplicatie---------", application);
        application.evaluator = localStorage.getItem("username");
        this.applicationService
          .updateApplicationClosed(application.id, false)
          .subscribe
          //{
          //   next: app => {
          //     this.router.navigate(["eval/applicationeval/", application.id]);
          //   }
          // }
          ();
        console.log("confirmed");
      }
    });
  }
  jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  }

  checkedItems: string[] = [];

  // Add a method to handle the checkbox change event
  onCheckboxChange(value: string): void {
    if (this.isChecked(value)) {
      // If the value is already checked, remove it from the array
      this.checkedItems = this.checkedItems.filter(item => item !== value);
    } else {
      // If the value is not checked, add it to the array
      this.checkedItems.push(value);
    }
  }

  // Helper method to check if a value is already checked
  isChecked(value: string): boolean {
    return this.checkedItems.includes(value);
  }
}
