import {Component, OnInit} from "@angular/core";
import {RapoarteService} from "../../../_services/rapoarte.service";
import {IRapoarte} from "../../../_models/rapoarte";
import {platformdetails} from "../../../../environments/platform-details";
import {SetariService} from "../../../_services/setari.service";

@Component({
  selector: "app-rapoarte",
  templateUrl: "./rapoarte.component.html",
  styleUrls: ["./rapoarte.component.scss"]
})
export class RapoarteComponent implements OnInit {
  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  private rapoarte = [] as IRapoarte[];

  constructor(public rapoarteService: RapoarteService,
              public setariService: SetariService) {
  }

  ngOnInit() {
    this.platform_name = platformdetails.title;
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.fetchData();
  }

  fetchData() {
    this.rapoarteService.getRapoarte().subscribe({
      next: rapoarte => {
        console.log("rapoarte:", rapoarte);
        if (rapoarte) this.rapoarte = rapoarte;
      }
    });
  }
}
