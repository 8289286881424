import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { IApplication } from "../_models/application";
@Injectable({
  providedIn: "root"
})
export class CrearePdfService {
  constructor(private http: HttpClient) {}

  getPdf(filename: string) {
    console.log(environment.apiUrl + "/pdf/getPdfFile/" + filename);
    return this.http
      .get(environment.apiUrl + "/pdf/getPdfFile/" + filename + ".pdf", {
        responseType: "blob"
      })
      .pipe(
        tap(data => console.log("All: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  crearePdf(application: IApplication, path: string = '/pdf/generateAndClose'): Observable<IApplication> {
    console.log(
      "crearePdf service",
      environment.apiUrl + path,
      application
    );
    return this.http
      .post<IApplication>(environment.apiUrl + path, application)
      .pipe(
        tap(
          data => console.log("creare pdf")
          // catchError(this.handleError))
        )
      );
  }
  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
