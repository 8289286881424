import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApplicationService } from "../../../_services/application.service";
import { IApplication } from "../../../_models/application";
import { Role } from "../../../_models/role";
import { Router } from "@angular/router";
import { SetariService } from "../../../_services/setari.service";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {platformdetails} from "../../../../environments/platform-details";
import {IInscriereStatus} from "../../../_models/inscriereStatus";
import {InscriereService} from "../../../_services/inscriere.service";

@Component({
  selector: "app-aplic-list",
  templateUrl: "./application-list.component.html",
  styleUrls: ["./application-list.component.scss"]
})
export class ApplicationListComponent implements OnInit, OnDestroy {
  pageTitle = "Your Application List";
  private _applications: IApplication[] = [];
  private _application: any;
  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name:string;
  private username: String;
  private role: String;
  private isInscriereFinalized: string;
  admitereAppId: number;
  applicationStatus: IInscriereStatus;
  private haveReply = false;

  public get applications(): IApplication[] {
    return this._applications;
  }
  public set applications(value: IApplication[]) {
    this._applications = value;
  }

  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private http: HttpClient,
    public setariService: SetariService,
    private inscriereService :InscriereService
  ) {}

  ngOnInit(): void {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });

    this._application = {
      denumire: "Aplicatie",
      step: 0,
      username: localStorage.getItem("username"),
      dateCreated: "",
      dateClosed: "",
      closed: false,
      valid: false
    };

    // console.log("localstorage", localStorage.getItem("username"));

    this.username = localStorage.getItem("username");
    this.role = localStorage.getItem("role");
    if (this.role == "ROLE_ADMIN") {
      this.applicationService.getApplications().subscribe({
        next: applications => {
          console.log("app for role admin", applications);
          this.applications = applications;
        }
      });
    } else {
      // console.log("on else ---------");
      this.applicationService
        .getApplicationsByUser(this.username.toString())
        .subscribe({
          next: applications => {
            this.haveReply = true;
            this.applications = applications;
            // console.log("app:::", this.applications);
          }
        });
    }

    this.platform_name = platformdetails.title;
    this.getFisaInscriereStatus();
  }

  downloadFile(filename: string = null): void {
    console.log("get pdf", localStorage.getItem("username"));
    this.http
      .get(
        environment.apiUrl +
        "/pdf/getPdfFile/" +
        localStorage.getItem("username") +
        ".pdf",
        {responseType: "blob" as "json"}
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        console.log(dataType);
        let binaryData = [];
        binaryData.push(response);

        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {type: dataType})
        );

        console.log(downloadLink.href);
        if (filename) downloadLink.setAttribute("download", filename);
        // document.body.appendChild(downloadLink);
        window.open(downloadLink.href, "_blank");
        // downloadLink.click();
      });
  }

  getPdf() {
    this.downloadFile("florin");
  }

  getFisaInscriereStatus() {
    this.isInscriereFinalized = "Partial";

    console.log("get application status from Promedicis");
    this.inscriereService.getFisaInscriereStatus()
      .subscribe((status: IInscriereStatus) => {
        console.log("got new status: " + status['result']);
        console.log("got new status: " + status['id']);
        console.log(status);
        this.applicationStatus = status;
        if (status.id) {
          this.isInscriereFinalized = "Complete";
          this.admitereAppId = status.id;
        } else {
          this.isInscriereFinalized = this.applicationStatus.result;
        }
      });
  }

  downloadFisaInscriere() {
    window.open(environment.admitereUrl + '/fisa-html?id=' + this.admitereAppId + '&action=edit'
      + '&token=' + this.getApplicationToken(), "_blank");
  }

  getApplicationToken() {
    return localStorage.getItem("token");
  }

  onComplete() {
    console.log("go to complete");
  }
  ngOnDestroy() {
    console.log("on Destroy");
  }

  creareAplicatie(_application: IApplication) {
    console.log("Creare aplicatie noua", this._application);
    this.applicationService.addApplication(this._application).subscribe({
      next: app => {
        console.log("dupa creare ", app["id"]);
        this.router.navigate(["/application/application/", app["id"]]);
      }
    });
  }
}
