import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ISetari } from "../_models/setari";
import { environment } from "../../environments/environment";
import { catchError, tap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { IRapoarte } from "../_models/rapoarte";
@Injectable({
  providedIn: "root"
})
export class RapoarteService {
  constructor(private http: HttpClient) {}

  getRapoarte(): Observable<IRapoarte[]> {
    return this.http
      .get<IRapoarte[]>(environment.apiUrl + "/rapoarte/getAll")
      .pipe(
        tap(data => console.log("get All users: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";

    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
