import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicationService } from "../../../_services/application.service";
import { Subject } from "rxjs";
import { SetariService } from "../../../_services/setari.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "app-home",
  templateUrl: "./application-detail.component.html",
  styleUrls: ["./application-detail.component.scss"]
})
export class ApplicationDetailComponent implements OnInit, OnDestroy {
  private eventsSubject: Subject<void> = new Subject<void>();
  public denumire_an: string;
  public denumire_perioada: string;
  params: any;
  applicationId: number;
  username: string;
  public platform_name: string;

  emitEventToChild(id: any) {
    console.log("emit to child", id);
    this.eventsSubject.next(id);
  }

  ngOnDestroy(): void {
    console.log("App list destroy");
  }

  public title: string = "Inscriere electronica";

  constructor(
    private applicationService: ApplicationService,
    public route: ActivatedRoute,
    public setariService: SetariService
  ) {
    this.params = this.route.params.subscribe(params => {
      console.log("on detail component::::::::::", params);
      this.applicationId = params["id"];
      console.log("on detail applicationId is ", this.applicationId);
    });
  }

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.username = localStorage.getItem("username");
    this.platform_name = platformdetails.title;
  }

  addApplication(application) {
    this.applicationService
      .addApplication(application)
      .subscribe(application => console.log("rggr5g"));
  }
  openOptions() {
    window.document.getElementById("dashboard-button").click();
  }

  public onStepChange(event: any): void {
    console.log(
      "step changed refresh component",
      event.previouslySelectedIndex
    );
    this.emitEventToChild(event.previouslySelectedIndex);
  }
}
