import {Component, OnInit, Input, ChangeDetectorRef} from "@angular/core";
import {ApplicationService} from "../../../_services/application.service";
import {IApplication} from "../../../_models/application";
import {SetariService} from "../../../_services/setari.service";
import {IApplicationHelper} from "../../../_models/applicationHelper";
import {Subject} from "rxjs";
import {MatDialog} from "@angular/material";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmationDialog} from "../../../custom-dialog/custom-dialog.component";
import {platformdetails} from "../../../../environments/platform-details";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: "app-application-list-admin",
  templateUrl: "./application-list-admin.component.html",
  styleUrls: ["./application-list-admin.component.scss"]
})
export class ApplicationListAdminComponent implements OnInit {
  private username: String;

  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  private role: String;
  private applications: IApplication[] = [];
  private applicationsTemp: IApplicationHelper[] = [];
  filteredApplications: IApplication[] = [];
  _listFilter = "";
  setGroupFilter$ = new Subject<any>();
  getGroupFilter = this.setGroupFilter$.asObservable();
  @Input() groupFilters: Object;
  @Input() searchByKeyword: string;

  private application: any;
  p: Number = 1;
  count: Number = 10;

  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient,
    private dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private ref: ChangeDetectorRef,
    public setariService: SetariService
  ) {
  }

  ngOnChanges(): void {
    console.log("onChange");
    if (this.groupFilters)
      this.filterApplications(this.groupFilters, this.applications);
  }

  groupFilters1($event) {
    if ($event) {
      this.applications = this.jsonCopy(this.applicationsTemp);
      this.filterApplications($event, this.applications);
    }
  }

  downloadApplicationFisaInscriere(userId: string) {
    window.open(environment.admitereUrl + '/fisa-html?user_id=' + encodeURIComponent(userId) + '&action=edit'
      + '&evaluator_token=' + this.getAdminToken(), "_blank");
  }

  getAdminToken() {
    return localStorage.getItem("token");
  }

  downloadFile(filename: string = null, userId: string): void {
    this.http
      .get(
        environment.apiUrl +
        "/pdf/getPdfFile/" +
        encodeURIComponent(userId) +
        ".pdf",
        {responseType: "blob" as "json"}
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        console.log(dataType);
        let binaryData = [];
        binaryData.push(response);

        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {type: dataType})
        );

        console.log(downloadLink.href);
        if (filename) downloadLink.setAttribute("download", filename);
        // document.body.appendChild(downloadLink);
        window.open(downloadLink.href, "_blank");
        // downloadLink.click();
      });
  }

  filterApplications(filters: any, applications: any) {
    let myArray = applications;
    this.filteredApplications = applications;
    const keys = Object.keys(filters);
    let b = [];
    keys.forEach(key => {
      b = myArray.filter(e =>
        String(e[key])
          .toLowerCase()
          .startsWith(String(filters[key]).toLowerCase())
      );

      console.log("b is:", b);
      myArray = b;
    });
    // applications = this.jsonCopy(myArray);
    // this.filteredApplications = this.jsonCopy(myArray);
    this.applications = this.jsonCopy(myArray);
  }

  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
    this.filteredApplications = this.listFilter
      ? this.performFilter(this.listFilter)
      : this.applications;
  }

  performFilter(filterBy: string): IApplication[] {
    filterBy = filterBy.toLocaleLowerCase();
    return this.applications.filter(
      (product: IApplication) =>
        product.denumire.toLocaleLowerCase().indexOf(filterBy) !== -1
    );
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");
    this.role = localStorage.getItem("role");
    this.platform_name = platformdetails.title;
    this.fetchData();
  }

  fetchData() {
    console.log("fetch data");
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.applicationService.getApplicationsHelper().subscribe({
      next: applications => {
        console.log("app for role eval", applications);
        this.applications = applications;
        this.applicationsTemp = this.jsonCopy(this.applications);
      }
    });
  }

  openDialog(application) {
    console.log(application.evaluator);
    if (application.evaluator === this.username) {
      this.router.navigate(["eval/applicationeval/", application.id]);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message:
            "Sunteti sigur ca doriti preluarea si verificarea dosarului ?",
          buttonText: {
            ok: "Da",
            cancel: "Nu"
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          // console.log("update eval---------", application);
          application.evaluator = localStorage.getItem("username");
          this.applicationService
            .updateApplication(
              application.id,
              application.evaluator,
              "In lucru"
            )
            .subscribe({
              next: app => {
                this.router.navigate(["eval/applicationeval/", application.id]);
              }
            });
          console.log("confirmed");
        }
      });
    }
  }

  openDialogRedeschide(application) {
    console.log(application.evaluator);

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Sunteti sigur ca doriti redeschiderea dosarului ?",
        buttonText: {
          ok: "Da",
          cancel: "Nu"
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        //update eval
        console.log("redeschidere aplicatie---------", application);
        application.evaluator = localStorage.getItem("username");
        this.applicationService
          .updateApplicationClosed(application.id, false)
          .subscribe
          //{
          //   next: app => {
          //     this.router.navigate(["eval/applicationeval/", application.id]);
          //   }
          // }
          ();
        console.log("confirmed");
      }
    });
  }

  jsonCopy(src) {
    return JSON.parse(JSON.stringify(src));
  }
}
