import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { IUser } from "../_models/user";
import { environment } from "../../environments/environment";
import { catchError, tap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(environment.apiUrl + "/user/getAll/").pipe(
      tap(data => console.log("get All users: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getEvaluators(): Observable<IUser[]> {
    return this.http.get<IUser[]>(environment.apiUrl + "/user/getAll/").pipe(
      tap(data => console.log("get All users: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getUser(id: number): Observable<IUser | undefined> {
    return this.http.get<IUser>(environment.apiUrl + "/user/get/" + id).pipe(
      tap(data => console.log("get user: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  updateUser(user: IUser) {
    console.log("here i update user", user);
    return this.http.post(environment.apiUrl + "/user/update/", user).pipe(
      tap(data => console.log("user updated:", data)),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    console.log("applicaton service httpresponse is", typeof err === "string");
    let errorMessage = "";

    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    console.error(err);
    return throwError(errorMessage);
  }
}
