import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { IInfPers } from "../../../_models/infpers";
import { Observable } from "rxjs";
import { InfPersUserService } from "../../../_services/inf-pers-user.service";

@Component({
  selector: "app-infpers-user",
  templateUrl: "./infpers-user.component.html",
  styleUrls: ["./infpers-user.component.scss"]
})
export class InfpersUserComponent implements OnInit, OnDestroy {
  stepId: number = 1;
  infPers: IInfPers;
  infPersForm: FormGroup;
  private eventsSubscription: any;
  @Input() events: Observable<void>;
  showhidepassport = false;
  marked = false;
  constructor(private infpersService: InfPersUserService) {
    console.log("create form!");
    this.infPersForm = this.createFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      numeAnterioare: new FormControl(),
      numeTata: new FormControl(),
      numeMama: new FormControl(),
      user_id: new FormControl(),
      application_id: new FormControl(),
      username: new FormControl(),
      dataNastere: new FormControl(),
      loculNasteriiTara: new FormControl(),
      loculNasteriiOras: new FormControl(),
      rezidentaTara: new FormControl(),
      rezidentaOras: new FormControl(),
      gen: new FormControl(),
      ci_passportTara: new FormControl(),
      ci_passportNr: new FormControl(),
      ci_passportCetatenie: new FormControl(),
      dataElib: new FormControl(),
      dataExp: new FormControl()
    });
  }

  onSubmit() {
    console.log(
      "check form !!! is prisitine or dirty,prisitne",
      this.infPersForm.dirty,
      this.infPersForm.pristine
    );
    // if (this.infPersForm.dirty) {
    //   console.log("form dirty");
    // }
    console.log("mesaj", this.infPersForm.value);
    this.infPers = this.infPersForm.value;
    console.log("here ", this.infPers);
    this.infPers.username = localStorage.getItem("username");
    this.infpersService
      .addInfPers(this.infPers, this.infPers.username)
      .subscribe();
    alert("Datele sunt salvate");
  }

  ngOnInit() {
    console.log("infpers nginit:::::::::::::::::::::");

    this.infpersService.getInfPersUser("florin").subscribe({
      next: infpers => {
        console.log("app infpers:::     ", infpers);
        this.infPersForm.setValue(infpers);
        console.log("infpers form ", this.infPersForm.value);
      }
    });

    this.eventsSubscription = this.events.subscribe(data => {
      this.doSomething(data);
    });
  }

  ngOnDestroy(): void {
    console.log("infpers destroyed.");
    this.eventsSubscription.unsubscribe();
  }

  doSomething(id: any) {
    if (this.stepId === id)
      console.log("events catch on child 0: refresh data", id);
  }

  toggleVisibility(e) {
    this.marked = e.target.checked;
  }
}
