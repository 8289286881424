import { Component, OnInit, Input } from "@angular/core";
import { IInscriereUser } from "../../../_models/inscriereUser";
import {FormGroup, FormControl} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { Facultati } from "../../../../api/applications/facultati";
import { Specializari } from "../../../../api/applications/specializari";
import { SelectFacultatiService } from "../../../_services/selectFacultati";
import { InscriereUserService } from "../../../_services/inscriere-user.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { DocumenteUserService } from "../../../_services/documente-user.service";
import { IDocumenteUserEval } from "../../../_models/documenteUserEval";
import { Globals } from "../../../_models/globals";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
@Component({
  selector: "app-inscriere-eval",
  templateUrl: "./inscriere-eval.component.html",
  styleUrls: ["./inscriere-eval.component.scss"]
})
export class InscriereEvalComponent implements OnInit {
  stepId: number = 0;
  userId: string;

  inscriereUser = {} as IInscriereUser;
  inscriereUserEvalForm: FormGroup;
  numeUniv: String =
    "Universitatea de Medicină și Farmacie „Grigore T. Popa” - Iași";

  facultatis: Facultati[];
  specializaris: Specializari[];
  params: any;
  applicationId: number;
  username: String;
  checkValidatInscr: boolean;
  checkObsInscr: boolean;
  observatii: String;
  documenteUser = {} as IDocumenteUserEval;

  private eventsSubscription: any;
  @Input() events: Observable<void>;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private selectFacultatiService: SelectFacultatiService,
    private inscriereUserService: InscriereUserService,
    private documenteUserService: DocumenteUserService,
    private globals: Globals
  ) {
    this.inscriereUserEvalForm = this.createFormGroup();

    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
    this.inscriereUser = globals.inscriereUserEval;
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(),
      applicationId: new FormControl(),
      // numeUniv: new FormControl(),
      numeFac: new FormControl(),
      // domeniu: new FormControl(),
      specializare: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      numeAnterioare: new FormControl(),
      cnp_passport: new FormControl(),
      cetateanStrain: new FormControl(),
      absolvent: new FormControl(),
      rrom: new FormControl(),
      validat: new FormControl(),
      observatii: new FormControl()
    });
  }

  get f() {
    return this.inscriereUserEvalForm.controls;
  }
  ngOnInit() {
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });

    this.username = localStorage.getItem("username");

    this.inscriereUserService
      .getInscriereUserEvalByAppId(this.applicationId)
      .subscribe({
        next: inscriereUser => {
          console.log("Get application user id: " + inscriereUser["user"]["userName"]);
          this.userId = inscriereUser["user"]["userName"];

          console.log("here inscr eval  :::", inscriereUser);
          delete inscriereUser["userId"];
          delete inscriereUser["numeUniv"];
          delete inscriereUser["user"];
          this.inscriereUserEvalForm.setValue(inscriereUser);
          this.checkValidatInscr = inscriereUser["validat"];
          this.updateSpecializari(inscriereUser["numeFac"]);
          this.inscriereUserEvalForm.controls["specializare"].setValue(
            inscriereUser["specializare"],
            {
              onlySelf: true
            }
          );
          this.inscriereUser = this.inscriereUserEvalForm.value;

        }
      });
    this.facultatis = this.selectFacultatiService.getFacultatis();
    this.documenteUserService
      .getDocumenteUserByAppId(this.applicationId)
      .subscribe({
        next: documenteUser => {
          if (documenteUser) {
            console.log("here ::: docuser inscr user", this.documenteUser);
          }
        }
      });

    this.eventsSubscription = this.events.subscribe(data => {
      this.doSomething(data);
    });
  }

  downloadApplicationFisaInscriere() {
    window.open(environment.admitereUrl + '/fisa-html?user_id=' + encodeURIComponent(this.userId) + '&action=edit'
      + '&evaluator_token=' + this.getEvalToken(), "_blank");
  }

  downloadFile(filename: string = null): void {
    this.http
      .get(
        environment.apiUrl +
        "/pdf/getPdfFile/" +
        encodeURIComponent(this.userId) +
        ".pdf",
        {responseType: "blob" as "json"}
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        console.log(dataType);
        let binaryData = [];
        binaryData.push(response);

        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {type: dataType})
        );

        console.log(downloadLink.href);
        if (filename) downloadLink.setAttribute("download", filename);
        // document.body.appendChild(downloadLink);
        window.open(downloadLink.href, "_blank");
        // downloadLink.click();
      });
  }

  getEvalToken() {
    return localStorage.getItem("token");
  }

  updateSpecializari(facultatiid) {
    console.log(
      "update Specializari",
      facultatiid,
      this.selectFacultatiService.getSpecializaris()
    );
    this.specializaris = this.selectFacultatiService
      .getSpecializaris()
      .filter(item => item.facultatiid == facultatiid);
    console.log("aaa specializari", this.specializaris);
  }

  updateSpecializari1(id) {
    this.inscriereUserEvalForm.setValue(["specializare", id]);
  }

  ngOnDestroy(): void {
    console.log("inscrierUser destroyed.");
    this.eventsSubscription.unsubscribe();
  }

  doSomething(data: any) {
    console.log(
      "events catch on inscr eval data:",
      data,
      "step.id :",
      this.stepId
    );
    this.globals.inscriereUserEval = this.inscriereUser;
    // console.log("isittrue:", this.stepId === parseInt(data.name)); // data.type === "save");
    if (this.stepId === parseInt(data.name) && data.value === "save") {
      this.onSubmit();
      console.log("events catch on child inscr user eval: save data", data);
    }
    if (this.stepId === parseInt(data.name) && data.value === "refresh") {
      console.log("events catch on child inscr user eval: refresh data", data);
      this.ngOnInit();
    }
  }

  onSubmit() {
    if (this.inscriereUser) {
      this.inscriereUser = this.inscriereUserEvalForm.value;
      this.inscriereUser.username = localStorage.getItem("username");
      this.inscriereUser.applicationId = this.applicationId;
      this.inscriereUser.valid = this.checkValidatInscr;
      console.log("update ---- eval ", this.inscriereUser);
      this.inscriereUser.step = this.stepId;
      console.log(
        "form value for update",
        this.applicationId,
        this.inscriereUser.valid,
        this.inscriereUser.observatii
      );
      this.inscriereUserService
        .updateInscriereUserEval(
          this.applicationId,
          this.inscriereUser.valid,
          this.inscriereUser.observatii
        )
        .subscribe();
    }
  }

  onChange(e) {
    console.log("chnahed", e.target.checked, e.target.id);

    this.checkValidatInscr = e.target.checked;
    this.checkObsInscr = !e.target.checked;
  }
}
