import {Component, OnInit, Input, ViewChild} from "@angular/core";
import { IInscriereUser } from "../../../_models/inscriereUser";
import {FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { Facultati } from "../../../../api/applications/facultati";
import { Specializari } from "../../../../api/applications/specializari";
import { SelectFacultatiService } from "../../../_services/selectFacultati";
import { InscriereUserService } from "../../../_services/inscriere-user.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material";
import { IDocumenteUser } from "../../../_models/documenteUser";
import { DocumenteUserService } from "../../../_services/documente-user.service";
import { MatStepper } from '@angular/material/stepper';
import {InscriereService} from "../../../_services/inscriere.service";
import {IInscriereStatus} from "../../../_models/inscriereStatus";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import get = Reflect.get;

@Component({
  selector: "app-inscriere-user",
  templateUrl: "./inscriere-user.component.html",
  styleUrls: ["./inscriere-user.component.scss"]
})

export class InscriereUserComponent implements OnInit {
  stepId: number = 0;
  admitereAppId: number;
  inscriereUserFull = {} as IInscriereUser;
  inscriereUser = {} as IInscriereUser;
  inscriereUserForm: FormGroup;
  numeUniv: String =
    "Universitatea de Medicină și Farmacie „Grigore T. Popa” - Iași";
  facultatis: Facultati[];
  specializaris: Specializari[];
  params: any;
  applicationId: number;
  username: String;
  documenteUser = {} as IDocumenteUser;
  http: HttpClient;
  submitted: boolean;

  //todo: add specific types
  isApplicationValid: 'Partial' | 'Complete' | string;
  applicationStatus: IInscriereStatus;

  public warningMessage: string;
  private eventsSubscription: any;

  @Input() events: Observable<void>;

  constructor(
    private route: ActivatedRoute,
    private selectFacultatiService: SelectFacultatiService,
    private inscriereUserService: InscriereUserService,
    private documenteUserService: DocumenteUserService,
    private inscriereService : InscriereService,
    private formBuilder: FormBuilder
  ) {
    this.inscriereUserForm = this.createFormGroup();

    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(),
      applicationId: new FormControl(),
      // numeUniv: new FormControl(),
      numeFac: new FormControl([Validators.required]),
      // domeniu: new FormControl(),
      specializare: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      numeAnterioare: new FormControl(),
      cnp_passport: new FormControl(),
      cetateanStrain: new FormControl(),
      absolvent: new FormControl(),
      rrom: new FormControl()
    });
  }

  ngOnInit() {
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });

    console.log("getInscrUsder by app id", this.applicationId);
    this.numeUniv =
      "Universitatea de Medicină și Farmacie „Grigore T. Popa” - Iași";
    this.username = localStorage.getItem("username");
    if (this.applicationId) {
      this.inscriereUserService
        .getInscriereUserByAppId(this.applicationId)
        .subscribe({
          next: inscriereUser => {
            if (inscriereUser) {
              this.inscriereUserFull = JSON.parse(JSON.stringify(inscriereUser));
              console.log("here :::", this.inscriereUserFull);
              delete inscriereUser["userId"];
              delete inscriereUser["numeUniv"];
              delete inscriereUser["validat"];
              delete inscriereUser["observatii"];
              delete inscriereUser["user"];
              this.inscriereUserForm.setValue(inscriereUser);
              this.updateSpecializari(inscriereUser["numeFac"]);
              console.log("app inscriere:::::", inscriereUser["specializare"]);
              this.inscriereUserForm.controls["specializare"].setValue(
                inscriereUser["specializare"],
                {
                  onlySelf: true
                }
              );
            }
            this.inscriereUser = this.inscriereUserForm.value;
            console.log("fomr inscriere:", this.inscriereUser);
          }
        });
      this.facultatis = this.selectFacultatiService.getFacultatis();
      this.documenteUserService
        .getDocumenteUserByAppId(this.applicationId)
        .subscribe({
          next: documenteUser => {
            if (documenteUser) {
              console.log("here ::: docuser inscr user", this.documenteUser);
            }
          }
        });
    }
    // this.updateDomenii(this.facultatis.id);
    this.eventsSubscription = this.events.subscribe(data => {
      this.doSomething(data);
    });

    this.getFisaInscriereStatus();
  }

  getApplicationToken() {
    return localStorage.getItem("token");
  }

  getEnvironment() {
    return environment;
  }

  getFisaInscriereStatus() {
    this.isApplicationValid = "Partial";

    console.log("get application status from Promedicis");
    this.inscriereService.getFisaInscriereStatus()
      .subscribe((status: IInscriereStatus) => {
        console.log("got new status: " + status['result']);
        console.log("got new status: " + status['id']);
        console.log(status);
        this.applicationStatus = status;
        if (status.id) {
          this.isApplicationValid = "Complete";
          this.admitereAppId = status.id;
        } else {
          this.isApplicationValid = this.applicationStatus.result;
        }
      });
  }

  updateSpecializari(facultatiid) {
    console.log(
      "update Specializari",
      facultatiid,
      this.selectFacultatiService.getSpecializaris()
    );
    this.specializaris = this.selectFacultatiService
      .getSpecializaris()
      .filter(item => item.facultatiid == facultatiid);
    console.log("aaa specializari", this.specializaris);
  }

  updateSpecializari1(id) {
    this.inscriereUserForm.setValue(["specializare", id]);
  }

  ngOnDestroy(): void {
    console.log("inscrierUser destroyed.");
    this.eventsSubscription.unsubscribe();
  }

  doSomething(id: any) {
    console.log("open dialog inscr user: ", id, this.stepId);
    this.onSubmit();
    // alertthis.dialog.open(ConfirmationDialog);
    // this.openDialog();
    console.log("inscr user id: stepid: ", id, this.stepId);
    if (this.stepId === id) {
      console.log("events catch on child inscr user: refresh data", id);
      console.log(
        "on page inscr : check form !!! is prisitine or dirty,prisitne",
        this.inscriereUserForm.dirty,
        this.inscriereUserForm.pristine
      );
    }
  }

  downloadFisaInscriere() {
    window.open(environment.admitereUrl + '/fisa-html?id=' + this.admitereAppId + '&action=edit'
      + '&token=' + this.getApplicationToken(), "_blank");
  }

  mapStatusToForm(form: IInscriereUser, app: IInscriereStatus) {
    //todo: if nu a completat in fisa
    form.specializare = app.specializare.id;
    form.firstName = app.datePersonale.prenume;
    if (app.datePersonale.nume_familie) {
      form.numeAnterioare = app.datePersonale.nume_familie_inainte_casatorie;
      form.lastName = app.datePersonale.nume_familie;
    } else {
      form.numeAnterioare = null;
      form.lastName = app.datePersonale.nume_familie_inainte_casatorie;
    }
    form.cnp_passport = app.datePersonale.cnp;
    form.cetateanStrain = app.datePersonale.cetatenie_romana !== 'da';
    form.rrom = app.datePersonale.etnie === 'ROM';
    form.absolvent = app.univ.univ_are_studii === 'da';
  }

  onSubmit() {
    console.log("[INSCRIERE] onsubmit");
    if (this.inscriereUser && this.isApplicationValid == 'Complete') {
      console.log("update ----  ", this.inscriereUser);
      //todo: extract from JSON fisa inscriere
      this.mapStatusToForm(this.inscriereUser, this.applicationStatus);
      this.inscriereUser.username = localStorage.getItem("username");
      this.inscriereUser.applicationId = this.applicationId;
      this.inscriereUser.step = this.stepId;
      console.log("form value for update", this.inscriereUser);
      localStorage.removeItem("error");
      this.inscriereUserService
      .updateInscriereUser(this.inscriereUser)
      .subscribe(
        data => {console.log('success', data);
          if (!this.documenteUser) {
            console.log("[INSCRIERE] onsubmit adaugare docuser");
            // this.documenteUser = this.documenteUserForm.value;
            // this.documenteUser.step = this.stepId;
            this.documenteUser.username = localStorage.getItem("username");
            this.documenteUserService
            .addDocumenteUser(this.documenteUser)
            .subscribe(() => {},
              error => {
                localStorage.setItem("error", error);
                console.error("server error for missing/invalid field", error);
            });
          }
        },
        error => {
          localStorage.setItem("error", error);
          console.error("server error for missing/invalid field", error);
        }
      );
    }
  }
}
