import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'confirmation-dialog-error',
  templateUrl: 'confirmation-dialog-error.html'
})

export class ConfirmationDialogError {
  message: string = "Are you sure?";
  confirmButtonText = "Yes";
  constructor( @Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<ConfirmationDialogError>) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
          this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
