import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { IInscriereUser } from "../_models/inscriereUser";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, tap } from "rxjs/operators";
import { IDocumenteUser } from "../_models/documenteUser";

@Injectable({
  providedIn: "root"
})
export class DocumenteUserService {
  constructor(private http: HttpClient) {}

  addDocumenteUser(documenteUser: IDocumenteUser): Observable<IDocumenteUser> {
    // inscriereUser.username = username;
    console.log(
      "DocumenteUserService",
      documenteUser,
      environment.apiUrl + "/docuser/add"
    );
    return this.http
      .post<IDocumenteUser>(environment.apiUrl + "/docuser/add", documenteUser)
      .pipe(
        tap(data => console.log("addDocUser add" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getDocumenteUserByAppId(id: number): Observable<IDocumenteUser[]> {
    return this.http
      .get<IDocumenteUser[]>(
        environment.apiUrl + "/docuser/getDocumenteUserByAppId/" + id
      )
      .pipe(
        tap(data => console.log("getDocumenteUserById  data", data)),
        // console.log("infpers for id: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  updateDocumenteUser(
    inscriereUser: IDocumenteUser
  ): Observable<IDocumenteUser> {
    // inscriereUser.username = username;
    console.log(
      "DocUserService",
      inscriereUser,
      environment.apiUrl + "/docuser/update"
    );
    return this.http
      .post<IDocumenteUser>(
        environment.apiUrl + "/docuser/update",
        inscriereUser
      )
      .pipe(
        tap(data => console.log("addDocUser updated" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }
  updateDocumenteUserField(inscriereUser: String): Observable<String> {
    // inscriereUser.username = username;
    console.log(
      "DocUserService updatefield",
      inscriereUser,
      environment.apiUrl + "/docuser/updatefield"
    );
    return this.http
      .post<String>(environment.apiUrl + "/docuser/updatefield", inscriereUser)
      .pipe(
        tap(data => console.log("docusefield updated" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
