import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthenticationService } from "../_services";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}

  canActivate() {
    if (!this.auth.isLoggedIn()) {
      console.log("auth not logged in!");
      this.router.navigate(["/login"]);
      return false;
    }
    return true;
    // if (localStorage.getItem("user")) {
    //   // logged in so return true
    //   return true;
    // }
    // // not logged in so redirect to login page
    // localStorage.clear();
    // this.router.navigate(["/login"]);
    // return false;
  }
}
