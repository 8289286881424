import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { MatDialogRef, MatDialog, MatDialogConfig } from "@angular/material";
import { AdaugareDialog } from "./adaugare-dialog.component";

@Injectable()
export class CommonModelService {
  denumire: any;
  date_inceput: any;
  date_sfarsit: any;
  constructor(public dialog: MatDialog) {}
  openDialog(): Observable<any> {
    const dialogRef = this.dialog.open(AdaugareDialog, {
      //   width: "250px",
      data: {
        denumire: this.denumire,
        date_inceput: this.date_inceput,
        date_sfarsit: this.date_sfarsit
      }
    });

    return dialogRef.afterClosed();
  }
}
