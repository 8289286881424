import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, tap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {IInscriereStatus} from "../_models/inscriereStatus";
@Injectable({
  providedIn: "root"
})
export class InscriereService {

  constructor(private http: HttpClient) {}

  getFisaInscriereStatus(): Observable<IInscriereStatus> {
    return this.http.get<IInscriereStatus>(
      environment.admitereUrl + "/fisa?webcall=true&action=get_by_token&token=" + localStorage.getItem("token"))
      .pipe(tap(data => console.log("get application status: " + JSON.stringify(data))),
        catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log("applicaton service httpresponse is", typeof err === "string");
    let errorMessage = "";

    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    console.error(err);
    return throwError(errorMessage);
  }
}
