import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "confirmation-dialog-pdf",
  templateUrl: "confirmation-dialog-pdf.html"
})
export class ConfirmationDialogPDF {
  src = "assets\\florin.pdf";
  //message: string = "Are you sure?";
  //confirmButtonText = "Yes";
  cancelButtonText = "Close";
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogPDF>
  ) {
    // if (data) {
    //   this.message = data.message || this.message;
    //   if (data.buttonText) {
    //     this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
    //     this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    //   }
    // }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
