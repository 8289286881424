import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { ApplicationService } from "../_services/application.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  form: FormGroup;
  @Output() autoSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() groupFilters1 = new EventEmitter();
  searchText: string = "";
  constructor(
    private fb: FormBuilder,
    private applicationHelperService: ApplicationService
  ) {}
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.form = this.fb.group({
      specializare: new FormControl(""),
      nume: new FormControl(""),
      prenume: new FormControl(""),
      numeAnt: new FormControl(""),
      cnp: new FormControl(""),
      evaluator: new FormControl(""),
      verificare: new FormControl(""),
      dataFinalizare: new FormControl(""),
      closed: new FormControl("")
    });
  }

  search(filters: any): void {
    console.log("search filter:", filters);
    Object.keys(filters).forEach(key =>
      filters[key] === "" ? delete filters[key] : key
    );
    this.groupFilters.emit(filters);

    console.log("here is search", filters);
    this.groupFilters1.emit(filters);
  }

  onSearchChange(e, v) {
    // alert("change" + e + v);
    console.log("onsearchchange", v["verificare"]);
    this.search(v);
  }
}
