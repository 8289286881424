import { Component, OnInit, Input } from "@angular/core";
// import {
//   FileUploader,
//   FileSelectDirective
// } from "ng2-file-upload/ng2-file-upload";
import { Observable } from "rxjs";
import { FormGroup, FormControl } from "@angular/forms";
import { IDocumenteUser } from "../../../_models/documenteUser";
import { DocumenteUserService } from "../../../_services/documente-user.service";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { ConfirmationDialogPDF } from "../../../custom-dialog-pdf/custom-dialog-pdf.component";
import { DocumenteUserEvalService } from "../../../_services/documente-user-eval.service";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { IDocumenteUserEval } from "../../../_models/documenteUserEval";
import { Globals } from "../../../_models/globals";
@Component({
  selector: "app-documente-eval",
  templateUrl: "./documente-eval.component.html",
  styleUrls: ["./documente-eval.component.scss"]
})
export class DocumenteEvalComponent implements OnInit {
  stepId: number = 1;
  uploadqueue: File[] = [];
  private eventsSubscription: any;
  @Input() events: Observable<void>;
  showhideetnie = false;
  showhideolimpic = false;
  etnie = false;
  olimp = false;
  documenteEvalForm: FormGroup;
  documenteUser = {} as IDocumenteUserEval;
  documUser: any = {};
  applicationId: number;
  username: String;
  params: any;
  show: boolean = true;

  error: string;
  // uploadResponse = { status: "", message: "", filePath: "" };

  constructor(
    private route: ActivatedRoute,
    private documenteUserService: DocumenteUserService,
    private dialog: MatDialog,
    private documenteUserEvalService: DocumenteUserEvalService,
    private router: Router,
    private http: HttpClient,
    private globals: Globals
  ) {
    // this.documenteUserForm = this.createFormGroup();
    this.documenteEvalForm = this.createFormEvalGroup();
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
    this.documenteUser = globals.docUserEval;
  }

  createFormEvalGroup() {
    return new FormGroup({
      id: new FormControl(),
      ci: new FormControl(),
      obsCi: new FormControl(),
      passport: new FormControl(),
      obsPass: new FormControl(),
      diplomaBac: new FormControl(),
      obsDiplBac: new FormControl(),
      foiMatricole: new FormControl(),
      obsFoiMat: new FormControl(),
      docCnredMen: new FormControl(),
      obsDocCnred: new FormControl(),
      diplomaLic: new FormControl(),
      obsDiplLic: new FormControl(),
      certLing: new FormControl(),
      obsCertLing: new FormControl(),
      certNastere: new FormControl(),
      obsCertNast: new FormControl(),
      certCas: new FormControl(),
      obsCertCas: new FormControl(),
      adevMed: new FormControl(),
      obsAdevMed: new FormControl(),
      chitanta: new FormControl(),
      obsChit: new FormControl(),
      recomandareRrom: new FormControl(),
      obsRecRom: new FormControl(),
      foto: new FormControl(),
      obsFoto: new FormControl(),
      olimpic: new FormControl(),
      obsOlimpic: new FormControl(),
      declaratieAutenticitate: new FormControl(),
      obsDeclaratieAutenticitate: new FormControl(),
      adeverintaAsistentaSociala: new FormControl(),
      obsAdeverintaAsistentaSoc: new FormControl(),
      acordStudiiTaxa: new FormControl(),
      obsAcordStudiiTaxa: new FormControl()
    });
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");
    // this.onSubmit();
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
    this.documenteUserEvalService
      .getDocumenteUserEvalByAppId(this.applicationId)
      .subscribe({
        next: documenteUser => {
          if (documenteUser) {
            delete documenteUser["userId"];
            delete documenteUser["applicationId"];
            console.log("aici este documUser : ", documenteUser);
            this.documenteEvalForm.setValue(documenteUser);
          }
          this.documenteUser = this.documenteEvalForm.value;
        }
      });
    this.documenteUserService
      .getDocumenteUserByAppId(this.applicationId)
      .subscribe(data => {
        console.log("docum User", data);
        this.documUser = data;
      });

    this.eventsSubscription = this.events.subscribe(data => {
      this.doSomething(data);
    });
  }

  ngOnDestroy(): void {
    console.log("infpers destroyed.");
    this.eventsSubscription.unsubscribe();
  }

  doSomething(data: any) {
    console.log(
      "events catch on doc eval data:",
      data,
      "step.id :",
      this.stepId
    );
    this.globals.docUserEval = this.documenteUser;
    // console.log("doc user eval globals is ", this.globals);
    if (this.stepId === parseInt(data.name) && data.value === "save") {
      if (!this.documenteUser.Id) {
        // console.log("docverificare is null ------------------");
        this.documenteUser = this.documenteEvalForm.value;
        this.documenteUser.applicationId = this.applicationId;
      }
      // console.log("docverificare ========== save", this.documenteUser);
      console.log("docuserEval form is dirty ", this.documenteEvalForm.dirty);
      if (this.documenteEvalForm.dirty) {
        this.documenteUserEvalService
          .updateDocumenteUser(this.documenteUser)
          .subscribe();
      }
    }
    if (this.stepId === parseInt(data.name) && data.value === "refresh") {
      console.log("refresh doc eval");
      this.ngOnInit();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogPDF, {
      data: {
        message: "Sunteti sigur ca doriti preluarea si verificarea dosarului ?",
        buttonText: {
          ok: "Da",
          cancel: "Nu"
        }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      //update eval

      console.log("confirmed");
    });
  }

  viewPdf(e) {
    console.log(e);
    switch (e.target.id) {
      case "vizCi": {
        this.downloadFile(this.documUser.ci, this.documUser.username);
        break;
      }
      case "vizPassport": {
        this.downloadFile(this.documUser.passport, this.documUser.username);
        break;
      }
      case "vizDiplomaBac": {
        this.downloadFile(this.documUser.diplomaBac, this.documUser.username);
        break;
      }
      case "vizFoiMatricole": {
        this.downloadFile(this.documUser.foiMatricole, this.documUser.username);
        break;
      }
      case "vizDocCnredMen": {
        this.downloadFile(this.documUser.docCnredMen, this.documUser.username);
        break;
      }
      case "vizDiplomaLic": {
        this.downloadFile(this.documUser.diplomaLic, this.documUser.username);
        break;
      }
      case "vizCertLing": {
        this.downloadFile(this.documUser.certLing, this.documUser.username);
        break;
      }
      case "vizCertNastere": {
        this.downloadFile(this.documUser.certNastere, this.documUser.username);
        break;
      }
      case "vizCertCas": {
        this.downloadFile(this.documUser.certCas, this.documUser.username);
        break;
      }
      case "vizAdevMed": {
        this.downloadFile(this.documUser.adevMed, this.documUser.username);
        break;
      }
      case "vizChit": {
        this.downloadFile(this.documUser.chitanta, this.documUser.username);
        break;
      }
      case "vizRecomandareRrom": {
        this.downloadFile(
          this.documUser.recomandareRrom,
          this.documUser.username
        );
        break;
      }
      case "vizFoto": {
        this.downloadFile(this.documUser.foto, this.documUser.username);
        break;
      }
      case "vizOlimpic": {
        this.downloadFile(this.documUser.olimpic, this.documUser.username);
        break;
      }
      case "vizAuth": {
        this.downloadFile(this.documUser.declaratieAutenticitate, this.documUser.username);
        break;
      }
      case "vizAdeverintaAsistentaSociala": {
        this.downloadFile(this.documUser.adeverintaAsistentaSociala, this.documUser.username);
        break;
      }
      case "vizAcordStudiiTaxa": {
        this.downloadFile(this.documUser.acordStudiiTaxa, this.documUser.username);
        break;
      }
    }
  }

  downloadFile(filename: string = null, id: string): void {
    const params = new HttpParams().set(
      "numefis",
      this.applicationId.toString()
    );
    const options = { params: params, responseType: "blob" as "json" };

    this.http
      .get(environment.apiUrl + "/pdf/getSomeFile/" + filename, {
        responseType: "blob" as "json",
        params
      })
      .subscribe((response: any) => {
        let dataType = response.type;
        console.log("datattype response:", dataType);
        let binaryData = [];
        binaryData.push(response);

        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );

        console.log(downloadLink.href);
        if (filename) downloadLink.setAttribute("download", filename);
        // document.body.appendChild(downloadLink);
        window.open(downloadLink.href, "_blank");
        // downloadLink.click();
      });
  }

  validateAll(e) {
    // force form to be uploaded when modified from aggregated buttons
    this.documenteEvalForm.markAsDirty();

    ['ci', 'passport', 'diplomaBac', 'foiMatricole', 'docCnredMen',
      'diplomaLic', 'certLing', 'certNastere', 'certCas', 'adevMed',
      // 'foto', 'acordStudiiTaxa'
      'chitanta', 'recomandareRrom', 'olimpic', 'declaratieAutenticitate', 'adeverintaAsistentaSociala'].forEach(key => {
      this.documenteEvalForm.controls[key].setValue(e);
    });
  }
}
