import { Injectable } from "@angular/core";
import { Facultati } from "./../../api/applications/facultati";
import { Specializari } from "./../../api/applications/specializari";

@Injectable()
export class SelectFacultatiService {
  getFacultatis() {
    return [
      new Facultati(1, "FACULTATEA DE BIOINGINERIE MEDICALĂ"),
      new Facultati(2, "FACULTATEA DE FARMACIE"),
      new Facultati(3, "FACULTATEA DE MEDICINĂ"),
      new Facultati(4, "FACULTATEA DE MEDICINĂ DENTARĂ")
    ];
  }

  getSpecializaris() {
    return [
      new Specializari(14, 1, "Balneo-fiziokinetoterapie şi recuperare"),
      new Specializari(4, 1, "Bioinginerie"),
      new Specializari(3, 2, "Farmacie"),
      new Specializari(6, 3, "Asistenţă medicală generală"),
      new Specializari(1, 3, "Medicină"),
      new Specializari(13, 3, "Nutriţie şi dietetică"),
      new Specializari(16, 4, "Asistență de Profilaxie Stomatologică"),
      new Specializari(2, 4, "Medicină dentară"),
      new Specializari(15, 4, "Tehnică dentară")
    ];
  }
}
