import {Component, Input, OnInit} from "@angular/core";
import {IInscriereUser} from "../../../_models/inscriereUser";
import {FormControl, FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {Facultati} from "../../../../api/applications/facultati";
import {Specializari} from "../../../../api/applications/specializari";
import {SelectFacultatiService} from "../../../_services/selectFacultati";
import {InscriereUserService} from "../../../_services/inscriere-user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DocumenteUserService} from "../../../_services/documente-user.service";
import {IDocumenteUserEval} from "../../../_models/documenteUserEval";
import {Globals} from "../../../_models/globals";
import {environment} from "../../../../environments/environment";
import {ApplicationService} from "../../../_services/application.service";
import {UserService} from "../../../_services/user.service";
import {IUser} from "../../../_models/user";
import {HttpClient} from "@angular/common/http";
import {SetariService} from "../../../_services/setari.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "secretar-inscriere-eval",
  templateUrl: "./inscriere-eval.component.html",
  styleUrls: ["./inscriere-eval.component.scss"]
})
export class SecretarInscriereEvalComponent implements OnInit {
  stepId: number = 0;
  userId: string;
  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  inscriereUser = {} as IInscriereUser;
  inscriereUserEvalForm: FormGroup;

  facultatis: Facultati[];
  specializaris: Specializari[];
  evaluatori: IUser[];
  params: any;
  applicationId: string[] = [];
  username: String;
  checkValidatInscr: boolean;
  checkObsInscr: boolean;
  observatii: String;
  documenteUser = {} as IDocumenteUserEval;

  private eventsSubscription: any;
  @Input() events: Observable<void>;
  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private selectFacultatiService: SelectFacultatiService,
    private inscriereUserService: InscriereUserService,
    private documenteUserService: DocumenteUserService,
    public userService: UserService,
    private globals: Globals,
    public setariService: SetariService
  ) {
    this.inscriereUserEvalForm = this.createFormGroup();

    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"].split(',');
    });
    this.inscriereUser = globals.inscriereUserEval;
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(),
      applicationId: new FormControl(),
      // numeUniv: new FormControl(),
      numeFac: new FormControl(),
      // domeniu: new FormControl(),
      specializare: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      numeAnterioare: new FormControl(),
      cnp_passport: new FormControl(),
      cetateanStrain: new FormControl(),
      absolvent: new FormControl(),
      rrom: new FormControl(),
      validat: new FormControl(),
      observatii: new FormControl(),
      evaluator: new FormControl()
    });
  }

  get f() {
    return this.inscriereUserEvalForm.controls;
  }
  ngOnInit() {
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"].split(',');
    });

    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    this.platform_name = platformdetails.title;

    this.username = localStorage.getItem("username");

    this.applicationService.getApplication(parseInt(this.applicationId[0]))
      .subscribe(application => {
        this.inscriereUserService
          .getInscriereUserEvalByAppId(parseInt(this.applicationId[0]))
          .subscribe({
            next: inscriereUser => {
              console.log("Get application user id: " + inscriereUser["user"]["userName"]);
              this.userId = inscriereUser["user"]["userName"];

              console.log("here inscr eval  :::", inscriereUser);
              delete inscriereUser["userId"];
              delete inscriereUser["numeUniv"];
              delete inscriereUser["user"];
              inscriereUser["evaluator"] = (application.evaluator) ? application.evaluator.userName : "";
              this.inscriereUserEvalForm.setValue(inscriereUser);
              this.updateSpecializari(inscriereUser["numeFac"]);
              this.inscriereUserEvalForm.controls["specializare"].setValue(
                inscriereUser["specializare"],
                {
                  onlySelf: true
                }
              );
              this.inscriereUser = this.inscriereUserEvalForm.value;

            }
          });
      });

    this.facultatis = this.selectFacultatiService.getFacultatis();

    this.userService.getEvaluators().subscribe(evaluatori => {
      this.evaluatori = evaluatori;
    });

    // this.documenteUserService
    //   .getDocumenteUserByAppId(this.applicationId)
    //   .subscribe({
    //     next: documenteUser => {
    //       if (documenteUser) {
    //         console.log("here ::: docuser inscr user", this.documenteUser);
    //       }
    //     }
    //   });

    // this.eventsSubscription = this.events.subscribe(data => {
    //   this.doSomething(data);
    // });
  }

  downloadApplicationFisaInscriere() {
    window.open(environment.admitereUrl + '/fisa-html?user_id=' + encodeURIComponent(this.userId) + '&action=edit'
      + '&evaluator_token=' + this.getEvalToken(), "_blank");
  }

  downloadFile(filename: string = null): void {
    this.http
      .get(
        environment.apiUrl +
        "/pdf/getPdfFile/" +
        encodeURIComponent(this.userId) +
        ".pdf",
        {responseType: "blob" as "json"}
      )
      .subscribe((response: any) => {
        let dataType = response.type;
        console.log(dataType);
        let binaryData = [];
        binaryData.push(response);

        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, {type: dataType})
        );

        console.log(downloadLink.href);
        if (filename) downloadLink.setAttribute("download", filename);
        // document.body.appendChild(downloadLink);
        window.open(downloadLink.href, "_blank");
        // downloadLink.click();
      });
  }

  getEvalToken() {
    return localStorage.getItem("token");
  }

  updateSpecializari(facultatiid) {
    console.log(
      "update Specializari",
      facultatiid,
      this.selectFacultatiService.getSpecializaris()
    );
    this.specializaris = this.selectFacultatiService
      .getSpecializaris()
      .filter(item => item.facultatiid == facultatiid);
    console.log("aaa specializari", this.specializaris);
  }

  updateSpecializari1(id) {
    this.inscriereUserEvalForm.setValue(["specializare", id]);
  }

  ngOnDestroy(): void {
    console.log("inscrierUser destroyed.");
    // this.eventsSubscription.unsubscribe();
  }

  doSomething(data: any) {
    console.log(
      "events catch on inscr eval data:",
      data,
      "step.id :",
      this.stepId
    );
    this.globals.inscriereUserEval = this.inscriereUser;
    // console.log("isittrue:", this.stepId === parseInt(data.name)); // data.type === "save");
    if (this.stepId === parseInt(data.name) && data.value === "save") {
      this.onSubmit();
      console.log("events catch on child inscr user eval: save data", data);
    }
    if (this.stepId === parseInt(data.name) && data.value === "refresh") {
      console.log("events catch on child inscr user eval: refresh data", data);
      this.ngOnInit();
    }
  }

  //todo: get lista de evaluatori de la facultatea respectiva

  onSubmit() {
    console.log(this.applicationId, this.inscriereUserEvalForm.get('evaluator').value, "In lucru");
    this.applicationService
      .updateApplication(this.applicationId.join(','), this.inscriereUserEvalForm.get('evaluator').value, "In lucru")
      .subscribe({
        next: app => {
          this.router.navigate(["secretar"]);
        }
      });
  }

  onChange(e) {
    console.log("chnahed", e.target.checked, e.target.id);

    this.checkValidatInscr = e.target.checked;
    this.checkObsInscr = !e.target.checked;
  }
}
