import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { CrearePdfService } from "../../../_services/crearepdf.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IApplication } from "../../../_models/application";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ApplicationService } from "../../../_services/application.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialog } from "../../../custom-dialog/custom-dialog.component";
import { AuthenticationService } from "../../../_services";
import { Observable } from "rxjs";
import { throwToolbarMixedModesError } from "@angular/material";
import { InscriereUserService } from "../../../_services/inscriere-user.service";
import { DocumenteUserService } from "../../../_services/documente-user.service";
import { IInscriereUser } from "../../../_models/inscriereUser";
import { IDocumenteUserEval } from "../../../_models/documenteUserEval";
import { DocumenteUserEvalService } from "../../../_services/documente-user-eval.service";
import { Globals } from "../../../_models/globals";

@Component({
  selector: "app-verif-aplic-eval",
  templateUrl: "./verif-aplic-eval.component.html",
  styleUrls: ["./verif-aplic-eval.component.scss"]
})
export class VerifAplicEvalComponent implements OnInit {
  stepId: number = 2;
  params: any;
  message = {};
  applicationId: number;
  isClosed: boolean = false;
  mesaj: String;
  globals: Globals;
  inscriereUser = {} as IInscriereUser;
  documenteUser = {} as IDocumenteUserEval;
  dosarComplet = false;

  private eventsSubscription: any;
  @Input() events: Observable<void>;
  constructor(
    private crearePdfService: CrearePdfService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private applicationService: ApplicationService,
    private dialog: MatDialog,
    public authService: AuthenticationService,
    private inscriereUserService: InscriereUserService,
    private documenteUserEvalService: DocumenteUserEvalService,
    public router: Router,
    globals: Globals
  ) {
    this.globals = globals;
  }

  ngOnInit() {
    console.log("nginit on verifsaPP ----");
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
    this.eventsSubscription = this.events.subscribe(data => {
      this.doSomething(data);
    });
  }

  onSubmit() {
    console.log("submited");
  }

  addIfShould(key, obs, prefix) {
    if (!this.globals.docUserEval[key])
      this.mesaj += `\n- Observatii ` + prefix + `: ` + ((this.globals.docUserEval[obs]) ? this.globals.docUserEval[obs] : "");
  }

  doSomething(data: any) {
    console.log("on verif app eval mesaj", data);
    console.log("on verif app eval globals is :", this.globals);

    if (true) {
      console.log("refresh this form eval");
      console.log(
        "globasl on eval -------------- ",
        this.globals.inscriereUserEval
      );
      this.mesaj = "";
      this.dosarComplet = this.globals.inscriereUserEval["validat"] &&
        this.globals.docUserEval["ci"] &&
        this.globals.docUserEval["passport"] &&
        this.globals.docUserEval["diplomaBac"] &&
        this.globals.docUserEval["foiMatricole"] &&
        this.globals.docUserEval["docCnredMen"] &&
        this.globals.docUserEval["diplomaLic"] &&
        this.globals.docUserEval["certLing"] &&
        this.globals.docUserEval["certNastere"] &&
        this.globals.docUserEval["certCas"] &&
        this.globals.docUserEval["adevMed"] &&
        this.globals.docUserEval["chitanta"] &&
        this.globals.docUserEval["recomandareRrom"] &&
        // this.globals.docUserEval["foto"] &&
        this.globals.docUserEval["olimpic"] &&
        this.globals.docUserEval["declaratieAutenticitate"] &&
        this.globals.docUserEval["adeverintaAsistentaSociala"];
        //todo: delete next release if this is not needed anymore
        // && this.globals.docUserEval["acordStudiiTaxa"];

      if (this.dosarComplet) {
        this.mesaj = `Dosarul dumneavoastră de înscriere la UMF IAȘI are toate câmpurile validate.`;
      } else {
        this.mesaj = `Vă anunțăm că dosarul dumneavoastră de înscriere la UMF IAȘI nu este complet.\nVa rugăm să completați dosarul în funcție de următoarele observații:`;

        // mesaj fisa de inscriere
        if (!this.globals.inscriereUserEval["valid"]) {
          this.mesaj += `\n\nFisa de Inscriere:\n- Observatii: ` +
            ((this.globals.inscriereUserEval["observatii"]) ? this.globals.inscriereUserEval["observatii"] : "");
        }

        // mesaj verificare documente
        this.mesaj += `\n\nVerificare Documente:`;
        if (this.globals.docUserEval) {
          this.addIfShould("ci", "obsCi", `CI`);
          this.addIfShould("passport", "obsPass", `Pasaport`);
          this.addIfShould("diplomaBac", "obsDiplBac", "Diploma Bacalaureat");
          this.addIfShould("foiMatricole", "obsFoiMat", "Foi Matricole");
          this.addIfShould("docCnredMen", "obsDocCnred", "DOCUMENT ELIBERAT DE C.N.R.E.D/M.E.C");
          this.addIfShould("diplomaLic", "obsDiplLic", "DIPLOMA DE LICENTA/ ADEVERINTA CU TAXA, FARA TAXA");
          this.addIfShould("certLing", "obsCertLing", "CERTIFICAT DE COMPETENTA LINGVISTICA PENTRU LIMBA ROMANA");
          this.addIfShould("certNastere", "obsCertNast", "CERTIFICAT DE NASTERE");
          this.addIfShould("certCas", "obsCertCas", "CERTIFICAT DE CASATORIE");
          this.addIfShould("adevMed", "obsAdevMed", "ADEVERINTA MEDICALA TIP");
          this.addIfShould("chitanta", "obsChit", "CHITANTA TAXA DE INSCRIERE");
          this.addIfShould("recomandareRrom", "obsRecRom", "RECOMANDARE SCRISA APARTENENȚA COMUNITATE RROMI");
          // this.addIfShould("foto", "obsFoto", "FOTOGRAFIE DE TIP BULETIN");
          this.addIfShould("olimpic", "obsOlimpic", "OLIMPIC");
          this.addIfShould("declaratieAutenticitate", "obsDeclaratieAutenticitate", "DECLARATIE AUTENTICITATE");
          this.addIfShould("adeverintaAsistentaSociala", "obsAdeverintaAsistentaSoc", "ADEVERINȚĂ ELIBERATĂ DE DIRECȚIA GENERALĂ DE ASISTENȚĂ SOCIALĂ ȘI PROTECȚIA COPILULUI");
          //todo: delete next release if this is not needed anymore
          // this.addIfShould("acordStudiiTaxa", "obsAcordStudiiTaxa", "ACORD DE A URMA STUDIILE ÎN REGIM CU TAXĂ");
        }

        if (this.mesaj.endsWith(`\n\nVerificare Documente:`)) {
          this.mesaj = this.mesaj.substr(0, this.mesaj.length - `\n\nVerificare Documente:`.length);
        }
      }
    }
    console.log("inscr eval second--------- ", this.inscriereUser);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Sunteti sigur ca doriti validarea dosarului ? ",
        buttonText: {
          ok: "Da",
          cancel: "Nu"
        }
      }
    });
    // const snack = this.snackBar.open("Snack bar open before dialog");

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        console.log("update eval---------", this.applicationId);
        this.applicationService
          .updateApplicationValidare(
            this.applicationId,
            localStorage.getItem("username"),
            "Validat"
          )
          .subscribe({
            next: app => {
              this.router.navigate(["eval/"]);
            }
          });
        console.log("confirmed");
      }
    });
  }

  openDialogGenerare() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: "Sunteti sigur ca doriti generare email ? ",
        buttonText: {
          ok: "Da",
          cancel: "Nu"
        }
      }
    });
    // const snack = this.snackBar.open("Snack bar open before dialog");

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.onSubmit();
        this.documenteUserEvalService
          .sendEmail(this.applicationId.toString(), this.mesaj)
          .subscribe({
            next: app => {
              this.router.navigate(["eval/"]);
            }
          });
      }
    });
  }
  logout() {
    this.authService.logout();
  }
}
