import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { IInscriereUser } from "../_models/inscriereUser";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, tap } from "rxjs/operators";
import { IDocumenteUser } from "../_models/documenteUser";
import { IDocumenteUserEval } from "../_models/documenteUserEval";

@Injectable({
  providedIn: "root"
})
export class DocumenteUserEvalService {
  constructor(private http: HttpClient) {}

  addDocumenteUser(
    documenteUser: IDocumenteUserEval
  ): Observable<IDocumenteUserEval> {
    // inscriereUser.username = username;
    console.log(
      "DocumenteUserService",
      documenteUser,
      environment.apiUrl + "/docuser/addeval"
    );
    return this.http
      .post<IDocumenteUserEval>(
        environment.apiUrl + "/docuser/addeval",
        documenteUser
      )
      .pipe(
        tap(data => console.log("addDocUser add eval" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getDocumenteUserEvalByAppId(id: number): Observable<IDocumenteUserEval[]> {
    return this.http
      .get<IDocumenteUserEval[]>(
        environment.apiUrl + "/docusereval/getDocumenteUserEvalByAppId/" + id
      )
      .pipe(
        tap(
          data => console.log("getDocumenteUserEvalByAppId  data", data)

          // console.log(
          //   "infpers for id: " +
          //     environment.apiUrl +
          //     "/infpers/getInfPersByUserId/" +
          //     id
          // )
        ),
        // console.log("infpers for id: " + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  updateDocumenteUser(
    inscriereUser: IDocumenteUserEval
  ): Observable<IDocumenteUser> {
    return this.http
      .post<IDocumenteUser>(
        environment.apiUrl + "/docusereval/updateeval",
        inscriereUser
      )
      .pipe(
        tap(data =>
          console.log("addDocUser eval updated" + JSON.stringify(data))
        ),
        catchError(this.handleError)
      );
  }
  updateDocumenteUserField(inscriereUser: String): Observable<String> {
    // inscriereUser.username = username;
    console.log(
      "DocUserService updatefield",
      inscriereUser,
      environment.apiUrl + "/docuser/updatefield"
    );
    return this.http
      .post<String>(environment.apiUrl + "/docuser/updatefield", inscriereUser)
      .pipe(
        tap(data => console.log("docusefield updated" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  sendEmail(applicationId: String, mesaj: String): Observable<IInscriereUser> {
    // inscriereUser.username = username;
    console.log(
      "Eval sendemail:",
      { applicationId, mesaj },
      environment.apiUrl + "/docusereval/sendemail"
    );
    return this.http
      .post<IInscriereUser>(environment.apiUrl + "/docusereval/sendemail", {
        applicationId,
        mesaj
      })
      .pipe(
        tap(data => console.log("email send" + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = "An error occurred: ${err.error.message}";
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
