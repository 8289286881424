import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../_services/authentication.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SetariService} from "../../../../_services/setari.service";
import {platformdetails} from "../../../../../environments/platform-details";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email: string;
  message: boolean = false;
  error: string;
  waiting = false;

  forgotForm: FormGroup;
  submitted = false;
  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  constructor(
    public authenticationService: AuthenticationService,
    public setariService: SetariService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });

    this.forgotForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ]
    });
    this.platform_name = platformdetails.title;
  }

  get f() {
    return this.forgotForm.controls;
  }

  onResetPassword() {
    this.waiting = true;
    this.submitted = true;
    this.message = false;

    this.authenticationService.sendPasswordResetEmail(this.email)
      .subscribe(result => {
        if (result['success']) {
          this.message = true;
        } else {
          let error = '';
          let messageArr = result['message']['email'];
          if (messageArr) {
            for (let i = 0; i < messageArr.length; i++) {
              error += messageArr[i] + ' ';
            }
          } else {
            error = result['message'];
          }
          this.error = error;
        }
        this.waiting = false;
      });
  }

}
