import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../../_services";
import { ActivatedRoute } from "@angular/router";
import { timer } from "rxjs/observable/timer";
import { Router } from "@angular/router";
import { SetariService } from "../../../_services/setari.service";
import {platformdetails} from "../../../../environments/platform-details";

@Component({
  selector: "app-register-confirm",
  templateUrl: "./register-confirm.component.html",
  styleUrls: ["./register-confirm.component.scss"]
})
export class RegisterConfirmComponent implements OnInit {
  token: string;
  message: string;
  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;

  constructor(
    public authService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    public router: Router,
    public setariService: SetariService
  ) {}

  ngOnInit() {
    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });
    const source = timer(5000);
    this.activeRoute.params.subscribe(params => {
      console.log("params :", params);
      if (params["token"]) {
        console.log("registerConfirm token is", params["token"]);
        this.token = params["token"];
      }
    });
    this.authService.registerConfirm(this.token).subscribe(
      data => {
        console.log("component", data);
        if (data) {
          console.log("messages");
          this.message = "Activare cu success!";
          const subscribe = source.subscribe(val => {
            console.log("timer");
            this.router.navigate(["/login"]);
          });
        }
      },
      error => {
        console.log("error messages");
        this.message = error;
      }
    );
    this.platform_name = platformdetails.title;
  }
}
