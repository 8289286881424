import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthenticationService } from "./../_services/authentication.service";
import jwt_decode from "jwt-decode";
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    console.log("role guard init", route.data);
    // this will be passed from the route config
    // on the data property
    // decode the token to get its payload;
    const token = localStorage.getItem("token");
    // console.log("token is :::::::::", token);
    // decode the token to get its payload
    const tokenPayload = jwt_decode(token);
    console.log("in guard++++++++++++ ", tokenPayload);
    const expectedRole = tokenPayload["roles"];
    console.log("get role from token", tokenPayload);
    console.log("islogged role guard", this.auth.isLoggedIn());
    if (!this.auth.isLoggedIn()) {
      console.log("user is not loggedin!");
      return false;
    } else {
      if (expectedRole === "ROLE_USER") {
        console.log("role guard expected role user", expectedRole);
        return true;
      }
      if (expectedRole === "ROLE_ADMIN") {
        console.log("role guard expected role admin", expectedRole);
        return true;
      }
      if (expectedRole === "ROLE_EVAL" || expectedRole === "ROLE_SECRETAR" || expectedRole === "ROLE_SECRETAR_COMISIE") {
        console.log("role guard expected role eval", expectedRole);
        return true;
      }
    }

    // if (!this.auth.isLoggedIn || tokenPayload.role !== expectedRole) {
    //   console.log("not expectedRole");
    //   this.router.navigate(["login"]);
    //   return false;
    // }
    return false;
  }
}
