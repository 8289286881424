import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-scris-intentie-user",
  templateUrl: "./scris-intentie-user.component.html",
  styleUrls: ["./scris-intentie-user.component.scss"]
})
export class ScrisIntentieUserComponent implements OnInit {
  maxChars = 2048;
  role = "";
  chars = 0;
  constructor() {}

  ngOnInit() {}
}
