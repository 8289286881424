import {Component, OnInit} from "@angular/core";
import {Location} from "@angular/common";
import {UserService} from "../../../_services/user.service";
import {ActivatedRoute} from "@angular/router";
import {IUser} from "../../../_models/user";
import {FormGroup, FormControl} from "@angular/forms";
import {platformdetails} from "../../../../environments/platform-details";
import {SetariService} from "../../../_services/setari.service";
import {Facultati} from "../../../../api/applications/facultati";
import {SelectFacultatiService} from "../../../_services/selectFacultati";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"]
})
export class UserDetailComponent implements OnInit {
  user: IUser | undefined;
  errorMessage = "";
  userDetailFormGroup: FormGroup;

  public denumire_an: string;
  public denumire_perioada: string;
  public platform_name: string;
  private facultatis: Facultati[];

  constructor(
    private _location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    private selectFacultatiService: SelectFacultatiService,
    public setariService: SetariService) {
    this.userDetailFormGroup = this.createFormGroup();
  }

  ngOnInit() {
    this.facultatis = this.selectFacultatiService.getFacultatis();
    const param = this.route.snapshot.paramMap.get("id");
    const id = +param;
    this.userService.getUser(id).subscribe(
      user => {
        console.log("user:", user);
        delete user["facultate"];
        this.user = user;
        console.log("this.user", this.user);
        this.userDetailFormGroup.setValue(this.user)
      },
      err => this.errorMessage = err
    );

    this.setariService.getLastSetari().subscribe(res => {
      this.denumire_an = res.denumire;
      this.denumire_perioada = res.date_inceput + "  -  " + res.date_sfarsit;
    });

    this.platform_name = platformdetails.title;

    // this.userDetailFormGroup.setValue(this.user);
  }

  onSubmit() {
    console.log("date submited");
    console.log("reactiveForm", this.userDetailFormGroup.value);
    this.userService
      .updateUser(this.userDetailFormGroup.value)
      .subscribe(data => {
        console.log("rggr5g", data);
        this._location.back();
      });
  }

  createFormGroup() {
    return new FormGroup({
      userId: new FormControl(),
      userName: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      facultateId: new FormControl(),
      password: new FormControl(),
      enabled: new FormControl(),
      role: new FormControl(),
      created: new FormControl()
    });
  }
}
