import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: "user-search",
  templateUrl: "./search-user.component.html"
})
export class SearchUserComponent implements OnInit {
  userForm: FormGroup;
  @Output() autoSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() groupFilters1 = new EventEmitter();
  searchText: string = "";
  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.userForm = this.fb.group({
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      role: new FormControl(""),
      userName: new FormControl("")
    });
  }

  search(filters: any): void {
    console.log("search filter:", filters);
    Object.keys(filters).forEach(key =>
      filters[key] === "" ? delete filters[key] : key
    );
    this.groupFilters.emit(filters);

    console.log("here is search", filters);
    this.groupFilters1.emit(filters);
  }

  onSearchChange(e, v) {
    console.log("onsearchchange", v["role"]);
    this.search(v);
  }
}
