import { Component, OnInit, Input } from "@angular/core";
import {
  FileUploader,
  FileSelectDirective
} from "ng2-file-upload";
import { Observable } from "rxjs";
import {FormGroup, FormControl, FormBuilder} from "@angular/forms";
import { IDocumenteUser } from "../../../_models/documenteUser";
import { DocumenteUserService } from "../../../_services/documente-user.service";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import { IKey } from "../../../_models/key";
import { queue } from "rxjs/internal/scheduler/queue";
import {IInscriereStatus} from "../../../_models/inscriereStatus";
import {InscriereService} from "../../../_services/inscriere.service";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
const UploadURL = environment.apiUrl + "/uploadFile/";
@Component({
  selector: "app-documente-user",
  templateUrl: "./documente-user.component.html",
  styleUrls: ["./documente-user.component.scss"]
})
export class DocumenteUserComponent implements OnInit {
  stepId: number = 1;
  uploadqueue: File[] = [];
  private eventsSubscription: any;
  @Input() events: Observable<void>;
  showhideetnie = false;
  showhideolimpic = false;
  etnie = false;
  olimp = false;
  documenteUserForm: FormGroup;
  documenteUser = {} as IDocumenteUser;
  applicationId: number;
  username: String;
  params: any;
  show: boolean = true;
  fileToUpload: File = null;
  isClosed: boolean = false;
  isValidForClosure: boolean;
  // checkNotCi: boolean = false;
  // checkNotPassport: boolean = false;
  // checkNotDiplBac: boolean = false;
  // checkNotFoiMat: boolean = false;
  // checkNotDocCnredMen: boolean = false;
  // checkNotDiplLic: boolean = false;
  // checkNotCertLing: boolean = false;
  // checkNotCertNast: boolean = false;
  // checkNotCertCas: boolean = false;
  // checkNotChit: boolean = false;
  // checkNotAdevMed: boolean = false;
  // checkNotRecomandareRom: boolean = false;
  // checkNotFoto: boolean = false;
  // checkNotOlimpic: boolean = false;

  error: string;
  uploadResponse = { status: "", message: "", filePath: "" };

  //({url: URL, itemAlias: 'photo'});
  public uploader: FileUploader = new FileUploader({});

  handleFileInput(files: FileList) {
    console.log("handleFileInput   :     ", files);
    this.fileToUpload = files.item(0);
    console.log("file to upload", this.fileToUpload);
  }

  constructor(
    private route: ActivatedRoute,
    public http: HttpClient,
    private documenteUserService: DocumenteUserService,
    private formBuilder: FormBuilder,
    private inscriereService : InscriereService
  ) {
    this.documenteUserForm = this.createFormGroup();
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
  }

  createFormGroup() {
    return new FormGroup({
      id: new FormControl(),
      ci: new FormControl(),
      passport: new FormControl(),
      diplomaBac: new FormControl(),
      foiMatricole: new FormControl(),
      docCnredMen: new FormControl(),
      diplomaLic: new FormControl(),
      certLing: new FormControl(),
      certNastere: new FormControl(),
      certCas: new FormControl(),
      adevMed: new FormControl(),
      chitanta: new FormControl(),
      recomandareRrom: new FormControl(),
      foto: new FormControl(),
      olimpic: new FormControl(),
      declaratieAutenticitate: new FormControl(),
      adeverintaAsistentaSociala: new FormControl(),
      acordStudiiTaxa: new FormControl()
    });
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");

    this.getFisaInscriereStatus();

    // this.onSubmit();
    this.params = this.route.params.subscribe(params => {
      this.applicationId = params["id"];
    });
    if (this.applicationId) {
      this.documenteUserService
        .getDocumenteUserByAppId(this.applicationId)
        .subscribe({
          next: documenteUser => {
            if (documenteUser) {
              console.log("here :::", documenteUser["ci"]);
              // if (documenteUser["ci"] == "Nu este cazul") {
              //   this.checkNotCi = true;
              // }
              // if (documenteUser["passport"] == "Nu este cazul") {
              //   this.checkNotPassport = true;
              // }
              // if (documenteUser["passport"] == "Nu este cazul") {
              //   this.checkNotPassport = true;
              // }
              // if (documenteUser["diplomaBac"] == "Nu este cazul") {
              //   this.checkNotDiplBac = true;
              // }
              // if (documenteUser["foiMatricole"] == "Nu este cazul") {
              //   this.checkNotFoiMat = true;
              // }
              // if (documenteUser["docCnredMen"] == "Nu este cazul") {
              //   this.checkNotDocCnredMen = true;
              // }
              // if (documenteUser["diplomaLic"] == "Nu este cazul") {
              //   this.checkNotDiplLic = true;
              // }
              // if (documenteUser["certLing"] == "Nu este cazul") {
              //   this.checkNotCertLing = true;
              // }
              // if (documenteUser["certNastere"] == "Nu este cazul") {
              //   this.checkNotCertNast = true;
              // }
              // if (documenteUser["certCas"] == "Nu este cazul") {
              //   this.checkNotCertCas = true;
              // }
              // if (documenteUser["adevMed"] == "Nu este cazul") {
              //   this.checkNotAdevMed = true;
              // }
              // if (documenteUser["chitanta"] == "Nu este cazul") {
              //   this.checkNotChit = true;
              // }
              // if (documenteUser["foto"] == "Nu este cazul") {
              //   this.checkNotFoto = true;
              // }
              // if (documenteUser["recomandareRrom"] == "Nu este cazul") {
              //   this.checkNotRecomandareRom = true;
              // }
              // if (documenteUser["olimpic"] == "Nu este cazul") {
              //   this.checkNotOlimpic = true;
              // }
              delete documenteUser["userId"];
              delete documenteUser["applicationId"];
              console.log("is checked______", documenteUser);
              this.documenteUserForm.setValue(documenteUser);
            }
            console.log("fomr docs:", this.documenteUserForm.value);
            this.documenteUser = this.documenteUserForm.value;
            console.log("after ci", this.documenteUser);
          }
        });
    }
    // this.documenteUser = this.documenteUserForm.value;
    console.log("here :::", this.uploaderCi);
    this.uploaderCi.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "ci"
      }
    });

    this.uploaderCi.onAfterAddingFile = file => {
      file.withCredentials = false;
      console.log("uploderCi:", this.uploaderCi);
      if (this.uploaderCi.queue.length > 1) {
        this.uploaderCi.queue[0].remove();
      }
    };
    this.uploaderCi.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log(
        "FileUpload ci :   uploaded:with succes",
        item,
        status,
        response
      );
      if (status == 200) {
        this.documenteUser.ci = this.uploaderCi.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderpassport.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "passport"
      }
    });
    this.uploaderpassport.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderpassport.queue.length > 1) {
        this.uploaderpassport.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderpassport.getNotUploadedItems().length,
        this.uploaderpassport.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderpassport.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.passport = this.uploaderpassport.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderDiplomaBac.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "diplomaBac"
      }
    });
    this.uploaderDiplomaBac.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderDiplomaBac.queue.length > 1) {
        this.uploaderDiplomaBac.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderDiplomaBac.getNotUploadedItems().length,
        this.uploaderDiplomaBac.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderDiplomaBac.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.diplomaBac = this.uploaderDiplomaBac.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderDocCnredMen.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "docCnredMen"
      }
    });
    this.uploaderDocCnredMen.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderDocCnredMen.queue.length > 1) {
        this.uploaderDocCnredMen.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderDocCnredMen.getNotUploadedItems().length,
        this.uploaderDocCnredMen.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderDocCnredMen.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.docCnredMen = this.uploaderDocCnredMen.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderDiplomaLic.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "diplomaLic"
      }
    });
    this.uploaderDiplomaLic.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderDiplomaLic.queue.length > 1) {
        this.uploaderDiplomaLic.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderDiplomaLic.getNotUploadedItems().length,
        this.uploaderDiplomaLic.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderDiplomaLic.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.diplomaLic = this.uploaderDiplomaLic.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderCertLing.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "certLing"
      }
    });
    this.uploaderCertLing.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderCertLing.queue.length > 1) {
        this.uploaderCertLing.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderCertLing.getNotUploadedItems().length,
        this.uploaderCertLing.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderCertLing.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.certLing = this.uploaderCertLing.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderFoiMatricole.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "foiMatricole"
      }
    });
    this.uploaderFoiMatricole.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderFoiMatricole.queue.length > 1) {
        this.uploaderFoiMatricole.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderFoiMatricole.getNotUploadedItems().length,
        this.uploaderFoiMatricole.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderFoiMatricole.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      if (status === 200) {
        this.documenteUser.foiMatricole = this.uploaderFoiMatricole.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderCertNastere.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "certNastere"
      }
    });
    this.uploaderCertNastere.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.certNastere = this.uploaderCertNastere.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderCertNastere.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderCertNastere.queue.length > 1) {
        this.uploaderCertNastere.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderCertNastere.getNotUploadedItems().length,
        this.uploaderCertNastere.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderCertCas.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "certCas"
      }
    });
    this.uploaderCertCas.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderCertCas.queue.length > 1) {
        this.uploaderCertCas.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderCertCas.getNotUploadedItems().length,
        this.uploaderCertCas.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderCertCas.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.certCas = this.uploaderCertCas.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };

    this.uploaderAdevMed.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "adevMed"
      }
    });
    this.uploaderAdevMed.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderAdevMed.queue.length > 1) {
        this.uploaderAdevMed.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderAdevMed.getNotUploadedItems().length,
        this.uploaderAdevMed.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderAdevMed.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.adevMed = this.uploaderAdevMed.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };

    this.uploaderChitanta.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "chitanta"
      }
    });
    this.uploaderChitanta.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderChitanta.queue.length > 1) {
        this.uploaderChitanta.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderChitanta.getNotUploadedItems().length,
        this.uploaderChitanta.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderChitanta.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.chitanta = this.uploaderChitanta.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };

    this.uploaderRecomandareRrom.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "recomandareRrom"
      }
    });
    this.uploaderRecomandareRrom.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderRecomandareRrom.queue.length > 1) {
        this.uploaderRecomandareRrom.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderRecomandareRrom.getNotUploadedItems().length,
        this.uploaderRecomandareRrom.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderRecomandareRrom.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.recomandareRrom = this.uploaderRecomandareRrom.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderFoto.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "foto"
      }
    });
    this.uploaderFoto.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderFoto.queue.length > 1) {
        this.uploaderFoto.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderFoto.getNotUploadedItems().length,
        this.uploaderFoto.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderFoto.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.foto = this.uploaderFoto.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderOlimpic.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "olimpic"
      }
    });
    this.uploaderOlimpic.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderOlimpic.queue.length > 1) {
        this.uploaderOlimpic.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderOlimpic.getNotUploadedItems().length,
        this.uploaderOlimpic.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderOlimpic.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.olimpic = this.uploaderOlimpic.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };
    this.uploaderDeclaratieAuthenticitate.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "declaratieAutenticitate"
      }
    });
    this.uploaderDeclaratieAuthenticitate.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderDeclaratieAuthenticitate.queue.length > 1) {
        this.uploaderDeclaratieAuthenticitate.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderDeclaratieAuthenticitate.getNotUploadedItems().length,
        this.uploaderDeclaratieAuthenticitate.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderDeclaratieAuthenticitate.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.declaratieAutenticitate = this.uploaderDeclaratieAuthenticitate.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };

    // Adeverinta Asisetenta Sociala
    this.uploaderAdeverintaAsistentaSociala.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "adeverintaAsistentaSociala"
      }
    });
    this.uploaderAdeverintaAsistentaSociala.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderAdeverintaAsistentaSociala.queue.length > 1) {
        this.uploaderAdeverintaAsistentaSociala.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderAdeverintaAsistentaSociala.getNotUploadedItems().length,
        this.uploaderAdeverintaAsistentaSociala.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderAdeverintaAsistentaSociala.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.adeverintaAsistentaSociala = this.uploaderAdeverintaAsistentaSociala.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };

    // Acord Studii cu Taxa
    this.uploaderAcordStudiiTaxa.setOptions({
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`
        }
      ],
      additionalParameter: {
        username: this.username,
        applicationId: this.applicationId,
        field: "acordStudiiTaxa"
      }
    });
    this.uploaderAcordStudiiTaxa.onAfterAddingFile = file => {
      file.withCredentials = false;
      if (this.uploaderAcordStudiiTaxa.queue.length > 1) {
        this.uploaderAcordStudiiTaxa.queue[0].remove();
      }
      console.log(
        "file addedd",
        this.uploaderAcordStudiiTaxa.getNotUploadedItems().length,
        this.uploaderAcordStudiiTaxa.queue[0].file.name
      );
      // this.documenteUser.ci = this.uploader1.queue[0].file.name;
    };
    this.uploaderAcordStudiiTaxa.onCompleteItem = (
      item: any,
      response: any,
      status: any
    ) => {
      if (status === 200) {
        this.documenteUser.acordStudiiTaxa = this.uploaderAcordStudiiTaxa.queue[0].file.name;
      } else {
        alert("Fisierul nu a putut fi incarcat/salvat!\n\n" + JSON.parse(response).message);
      }
    };

    this.eventsSubscription = this.events.subscribe(data => {
      this.doSomething(data);
    });
  }

  clearFiles() {
    this.uploadqueue = [];
  }

  ngOnDestroy(): void {
    console.log("infpers destroyed.");
    this.eventsSubscription.unsubscribe();
  }

  doSomething(id: any) {
    console.log("doc user id:", id, this.stepId);
    if (this.stepId === id) {
      console.log("events catch on child doc user: refresh data", id);
      console.log(
        "on page doc : check form !!! is prisitine or dirty,prisitne",
        this.documenteUserForm.dirty,
        this.documenteUserForm.pristine
      );
      this.onSubmit();
      // alert("Salvati doc user?");
    }
  }
  toggleEtnie(e) {
    this.etnie = e.target.checked;
  }
  toggleOlimp(e) {
    this.olimp = e.target.checked;
  }

  onChange(e) {
    console.log("chnahed", e.target.checked, e.target.id);
  }

  onSubmit() {
    console.log("[DOCUMENTE] onsubmit");
    // let file UPLOAD do its thing; do not override from here to avoid issues
    if (true) {
      return;
    }

    if (!this.documenteUser) {
      console.log("[DOCUMENTE] onsubmit adaugare docuser");
      this.documenteUser = this.documenteUserForm.value;
      this.documenteUser.step = this.stepId;
      this.documenteUser.username = localStorage.getItem("username");
      this.documenteUserService
        .addDocumenteUser(this.documenteUser)
        .subscribe();
    } else {
      console.log("[DOCUMENTE] onsubmit update docuser");
      this.documenteUser = this.documenteUserForm.value;
      this.documenteUser.username = localStorage.getItem("username");
      this.documenteUser.applicationId = this.applicationId;
      this.documenteUser.step = this.stepId;
      console.log("form value for update", this.documenteUser);
      this.documenteUserService
        .updateDocumenteUser(this.documenteUser)
        .subscribe();
    }
    // if (this.infPersForm.dirty) {
    //   console.log("form dirty");
    // }

    // alert("Datele sunt salvate");
  }
  public remove(event, item) {
    // alert("Open " + this.uploaderCi.clearQueue);
  }

  deleteUploadedFile(key: string) {
    console.log('Requested to delete: ' + key);
    let data = {
      applicationId: this.applicationId,
      field: key
    };

    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
      httpParams = httpParams.append(key, data[key]);
    });

    return this.http.post(environment.apiUrl + "/deleteFile/", httpParams)
      .pipe(
        map((response: Response) => {
          return response;
        })
      ).subscribe(newDocUser => {
        console.log('Am primit: ', newDocUser);
        delete newDocUser["userId"];
        delete newDocUser["applicationId"];
        this.documenteUser[key] = newDocUser[key];
      }, error => {
        alert(error);
      });
  }

  getFisaInscriereStatus() {
    this.inscriereService.getFisaInscriereStatus()
      .subscribe((status: IInscriereStatus) => {
        if (status.id) {
          this.isValidForClosure = true;
        } else {
          this.isValidForClosure = false;
        }
      });
  }

  // isInscriereValidForClosure() {
  //   return this.isValidForClosure;
  // }

  /**
   * Uploads methods
   */
  public uploaderCi: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file",
    additionalParameter: {
      username: this.username
    }
  });
  public uploaderpassport: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderDiplomaBac: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderFoiMatricole: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderDocCnredMen: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  }); //uploaderDiplomaLic
  public uploaderDiplomaLic: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderCertLing: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderCertNastere: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderCertCas: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderAdevMed: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderChitanta: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderRecomandareRrom: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderFoto: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderOlimpic: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderDeclaratieAuthenticitate: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderAdeverintaAsistentaSociala: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
  public uploaderAcordStudiiTaxa: FileUploader = new FileUploader({
    url: UploadURL,
    itemAlias: "file"
  });
}
